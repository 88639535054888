<header class="header-section">
    <div class="facility-name" *ngIf="this.isFacilityUser()">
        {{this.facilityName}}
    </div>
    <div class="row align-items-center">
        <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
            <div class="page-title">
                <h1>{{ getTitle() }}</h1>
            </div>
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
            <nav class="navbar navbar-expand justify-content-end">
                <div class="header-main-search">
                    <div *ngIf="notificationUnreadCount > 0" class="unread-messages-alert" style="display:none;">
                        <a class="nav-link" href="javascript:;" 
                            [routerLink]="['auth/messages']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                            Unread messages
                        </a>
                    </div>
                    <div class="header-search-box" [ngClass]="{'search-open': isSearchOpen}">
                        <div class="search-ic-box">
                            <a href="javascript:;" class="search-ic" (click)="toggleSearch()"><span
                                    class="custom-icons icon-search"></span></a>
                            <a href="javascript:;" class="close-ic" (click)="toggleSearch()"><span
                                    class="custom-icons icon-close-small"></span></a>
                        </div>
                        <kendo-autocomplete  #autocomplete 
                        (focus)="autocomplete.toggle()" [data]="searchName" (valueChange)="checkIsLinkExists($event)"
                            [filterable]="true" [kendoDropDownFilter]="filterSettings" placeholder="Find Menu">
                        </kendo-autocomplete>
                    </div>
                    <div class="header-search-box patient-search-box" [ngClass]="{'patient-search-open': isPatientSearchOpen}">
                        <div class="search-ic-box">
                            <a href="javascript:;" class="search-ic" (click)="patientSearch()"><span
                                    class="custom-icons icon-user"></span></a>
                            <a href="javascript:;" class="close-ic" (click)="patientSearch()"><span
                                    class="custom-icons icon-close-small"></span></a>
                        </div>
                        <kendo-autocomplete #autocomplete1 
                        (focus)="autocomplete1.toggle()" [data]="patientSearchName" [valueField]="'fullName'" (valueChange)="checkIsPatientExists($event)"
                            [filterable]="true" [kendoDropDownFilter]="filterSettings" placeholder="Find Patient">
                        </kendo-autocomplete>
                    </div>
                </div>
                <div class="common-navbar">
                    <ul class="navbar-nav align-items-center">
                        <li class="nav-item">
                            <div kendoTooltip class="grid-tooltip">
                            <a class="nav-link" title="{{notificationUnreadCount}} unread / {{notificationCount}} total"
                                href="javascript:;" [routerLink]="['auth/messages']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                                <span *ngIf="notificationUnreadCount > 0" class="nav-status unread-messages-alert"></span>
                                <span class="custom-icons icon-bell"></span>
                            </a>
                        </div>
                        </li>
                        <li class="nav-item dropdown profile-dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdownMenuLink"
                                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="profile-img">
                                    <img [src]="profileImageUrl" (error)="getdefultimage()" alt="...">
                                </span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <h3 class="user-name">{{userName}}</h3>
                                </li>
                                <li><a class="dropdown-item" href="javascript:;" [routerLink]="['auth/profile']"
                                        routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                        (click)="setTitle('Profile')"><span
                                            class="custom-icons icon-user"></span>Profile</a></li>

                                <li><a class="dropdown-item" href="javascript:;"
                                        [routerLink]="['auth/profile/change-password']" routerLinkActive="active-link"
                                        [routerLinkActiveOptions]="{exact: true}"
                                        (click)="setTitle('Change Password')"><span class="custom-icons icon-lock"></span>
                                        
                                        Change Password</a></li>

                                <li><a class="dropdown-item" href="javascript:;" [routerLink]="['auth/setting']"
                                        routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                        (click)="setTitle('Settings')"><span
                                            class="custom-icons icon-settings"></span>Settings</a></li>
                                <li><a class="dropdown-item" href="javascript:;"><span
                                            class="custom-icons icon-help"></span>Help</a></li>
                                <li><a class="dropdown-item" href="javascript:;"><span
                                            class="custom-icons icon-info"></span>About Cereus</a></li>
                                <li><a class="dropdown-item" href="javascript:;" (click)="logout()"><span
                                            class="custom-icons icon-logout"></span>Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<!-- Notification modal start -->
<div class="modal fade right modal-right" id="Notifications-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><span class="custom-icons icon-bell me-2"></span>
                    Notifications</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modal-inner-tabs">
                    <ul class="nav nav-tabs common-nav-tab d-flex justify-content-between" id="myTab" role="tablist">
                        <li (click)="isTask = true" class="nav-item w-50" role="presentation">
                            <button class="nav-link active w-100" id="home-tab" data-bs-toggle="tab"
                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                aria-selected="true">Tasks</button>
                        </li>
                        <li (click)="isTask = false" class="nav-item w-50" role="presentation">
                            <button class="nav-link w-100" id="profile-tab" data-bs-toggle="tab"
                                data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                aria-selected="false">Messages</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="add-task-btn">
                            <a href="javascript:;" data-bs-dismiss="modal" class="btn btn-md btn-secondary w-100"
                                (click)="taskModelOpen()"><span class="custom-icons icon-plus me-1"></span> Task</a>
                        </div>
                        <ul class="notifications-row">
                            <li class="notifications-cell" *ngFor="let item of taskViewList; let i = index;"
                                (click)="taskDetailsOpen(item.taskId)">
                                <div kendoTooltip class="title-box">
                                    <h5>{{item.taskSubject}}</h5>
                                    
                                    <span *ngIf="item.taskName == 'High-Priority'"><span class="custom-icons dot-ic high" [title]="item.taskName"></span></span>
                                    <span *ngIf="item.taskName == 'General'"><span class="custom-icons dot-ic general" [title]="item.taskName"></span></span>
                                    <span *ngIf="item.taskName == 'Low-Priority'"><span class="custom-icons dot-ic low" [title]="item.taskName"></span></span>
                                </div>
                                <p>{{item.details}} </p>
                                <span>{{item.tillNow}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <ul class="notifications-row message-box">
                            <li *ngFor="let notify of notifications" class="notifications-cell">
                                <div (click)="readNotificationByNotificationId(notify);"
                                    [ngClass]="{'no-read': notify.isReaded == 0}">
                                    <h5> {{notify.title}} </h5>
                                    <p> {{notify.message}} </p>
                                    <span> {{notify.tillNow}} </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <p *ngIf="isTask" class="m-0 text-center w-100" data-bs-dismiss="modal"><b><a href="javascript:;"
                            [routerLink]="['auth/services/vob']" [queryParams]="{ tab: 'taskmanagement'}">See
                            All</a></b></p>
            </div>
        </div>
    </div>
</div>
<!-- Notification modal End -->

<!-- Add Task Popup Start -->
<kendo-dialog title="{{taskManagementTitle}}" *ngIf="addTask" (close)="taskModelClose()" class="kendo-modal modal-lg">
    <div class="common-popup-space">
        <form [formGroup]="taskManagementForm" (ngSubmit)="addupdateTaskInfo()" autocomplete="off">

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Date</label>
                        <kendo-datetimepicker [disabled]="true" formControlName="taskDate">
                        </kendo-datetimepicker>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="taskTypeId" class="form-label">Task Type <span
                                class="field-required">*</span></label>
                        <kendo-dropdownlist [data]="selectTaskType" [textField]="'taskName'" [valueField]="'taskTypeId'"
                            [valuePrimitive]="true" [kendoDropDownFilter]="filterSettings"
                            [defaultItem]="{ taskName: 'Select Task Type', taskTypeId: null }"
                            formControlName="taskTypeId" [ngClass]="{ 'is-invalid':   displayMessage['taskTypeId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['taskTypeId']" class="invalid-feedback">
                            {{ displayMessage['taskTypeId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Facility <span class="field-required">*</span></label>
                        <kendo-dropdownlist [data]="selectFacility" [textField]="'facilityName'"
                            [valueField]="'facilityMasterId'" [valuePrimitive]="true"
                            [kendoDropDownFilter]="filterSettings"
                            [defaultItem]="{ facilityName: 'Select Facility', facilityMasterId: null }"
                            formControlName="facilityId" (selectionChange)="checkIsCaseExists($event)"
                            [ngClass]="{ 'is-invalid':   displayMessage['facilityId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['facilityId']" class="invalid-feedback">
                            {{ displayMessage['facilityId'] }}
                        </div>

                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <!-- <pre>{{selectCaseId | json}}</pre> -->
                        <label class="form-label">Case# <span class="field-required">*</span></label>
                        <kendo-dropdownlist [data]="selectCaseId" [textField]="'caseId'" [valueField]="'caseId'"
                            [valuePrimitive]="true" [kendoDropDownFilter]="filterSettings"
                            [defaultItem]="{ caseId: 'Select Case Id' }" formControlName="caseId"
                            [ngClass]="{ 'is-invalid':   displayMessage['caseId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['caseId']" class="invalid-feedback">
                            {{ displayMessage['caseId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Assign To <span class="field-required">*</span></label>
                        <kendo-dropdownlist [data]="selectUser" [textField]="'fullName'" [valueField]="'userId'"
                            [valuePrimitive]="true" [kendoDropDownFilter]="filterSettings"
                            [defaultItem]="{ fullName: 'Select User', userId: null }" formControlName="assignedTo"
                            [ngClass]="{ 'is-invalid':   displayMessage['assignedTo']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['assignedTo']" class="invalid-feedback">
                            {{ displayMessage['assignedTo'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label" for="targetStartDate">Due Date <span
                                class="field-required">*</span></label>
                        <kendo-datepicker calendarType="classic" placeholder="mm/dd/yyyy"
                            [disabledDates]="disabledDates" formControlName="dueDate"
                            [ngClass]="{ 'is-invalid':   displayMessage['dueDate']}"></kendo-datepicker>
                        <div *ngIf="displayMessage['dueDate']" class="invalid-feedback">
                            {{ displayMessage['dueDate'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label for="taskType" class="form-label">Subject <span class="field-required">*</span></label>
                        <input type="text" class="form-control" formControlName="taskSubject"
                            placeholder="Verification Required"
                            [ngClass]="{ 'is-invalid':   displayMessage['taskSubject']}">
                        <div *ngIf="displayMessage['taskSubject']" class="invalid-feedback">
                            {{ displayMessage['taskSubject'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label for="taskType" class="form-label">Details <span class="field-required">*</span></label>
                        <textarea class="form-control" formControlName="details" placeholder="Description"
                            maxlength="200" [ngClass]="{ 'is-invalid':   displayMessage['details']}"></textarea>
                        <div *ngIf="displayMessage['details']" class="invalid-feedback">
                            {{ displayMessage['details'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Status <span class="field-required">*</span></label>
                        <kendo-dropdownlist [data]="selectStatus" [textField]="'statusName'" [valueField]="'statusId'"
                            [valuePrimitive]="true" [kendoDropDownFilter]="filterSettings"
                            [defaultItem]="{ statusName: 'Select Status', statusId: null }" formControlName="statusId"
                            [ngClass]="{ 'is-invalid':   displayMessage['statusId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['statusId']" class="invalid-feedback">
                            {{ displayMessage['statusId'] }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label">Notify Me &nbsp;&nbsp;</label>
                        <input type="checkbox" formControlName="notifyMe" #notification kendoCheckBox />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Attachment</label>
                      
                        <kendo-fileselect formControlName="filePath">
                            <kendo-fileselect-messages dropFilesHere="Drag & Drop File" select="Browse">
                            </kendo-fileselect-messages>
                        </kendo-fileselect>
                        <p formControlName="filePath"></p>
                         <ng-template kendoGridCellTemplate let-dataItem>
                                <div  [innerHtml]="dataItem.filePath"></div>
                         </ng-template>
                    </div>
                </div>

            </div>
            <kendo-dialog-actions class="d-block text-end">
                <button kendoButton type="submit" *ngIf="!disabled" class="btn btn-primary btn-md">Save</button>
                <button kendoButton (click)="taskModelClose()" class="btn btn-cancel btn-md">Cancel</button>
            </kendo-dialog-actions>
        </form>
    </div>
</kendo-dialog>
<div kendoDialogContainer></div>
<!-- Add Task Popup End -->

<!-- Task Details Popup Start -->
<kendo-dialog title="Task Details" *ngIf="taskDetails" (close)="taskDetailsClose()"  class="kendo-modal modal-md task-detail-modal">
    <div class="common-popup-info">
        <div class="task-box">
            <h5 class="mb-2">{{this.info.taskSubject}}</h5>
        <span class="priority high"  *ngIf="info.taskName == 'High-Priority'" >{{info.taskName}}</span>
        <span class="priority general"  *ngIf="info.taskName == 'General'" >{{info.taskName}}</span>
        <span class="priority low" *ngIf="info.taskName == 'Low-Priority'">{{info.taskName}}</span>
        </div>
        <p>{{this.info.details}}</p>
        <p>{{this.info.createdDate | date:'M/d/yy, h:mm a'}}</p>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="taskDetailsClose()" class="btn btn-cancel btn-md">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Task Details Popup End -->

<!-- Unread Messages Popup Start -->
<kendo-dialog title="Unread Messages" *ngIf="showUnreadMessagesPopup" (close)="unreadMessagesPopupClose()"  class="kendo-modal modal-lg danger text-center">
    <div class="common-popup-info">
        <div>
            <h5 class="mb-2">You have {{this.notificationUnreadCount}} <a class="active-link" href="javascript:;" (click)="unreadMessagesPopupClickLink()">unread message{{this.notificationUnreadCount > 1 ? 's' : ''}}</a> that requires review</h5>
        </div>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="unreadMessagesPopupClose()" class="btn btn-cancel btn-md">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Unread Messages Popup End -->

<!-- Logout Confirmation -->
<kendo-dialog title="Cereus Software" *ngIf="showLogoutConfirmationPopup" (close)="onLogoutClose()"  class="kendo-modal modal-sm">
    <div class="common-popup-space text-center">
        <h5 class="mb-2">Are you sure want to Logout ?</h5>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="onLogoutClose()" class="btn btn-cancel btn-md">Cancel</button>
        <button kendoButton (click)="doLogout()" class="btn btn-primary btn-md">Logout</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Logout Confirmation -->