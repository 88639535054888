import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA  } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { LabelModule } from "@progress/kendo-angular-label";
import { IconsModule } from '@progress/kendo-angular-icons';

// Component
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { TaskManagementComponent } from '../common/component/task-management/task-management.component';
import { LetterHeadComponent } from '../common/component/letterhead/letterhead.component';
import { PagerModule } from '@progress/kendo-angular-pager';
import { ChartModule, ChartsModule } from "@progress/kendo-angular-charts";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HistoryComponent } from '../common/history/history.component';
import { DocumentTabComponent } from '../common/component/document-tab/document-tab.component';
import { SingleBarChartComponent } from '../common/component/single-bar-chart/single-bar-chart.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomDatePipeModule } from './utility/local-date-time-pipe.module';
import { CereusDialogComponent } from './components/cereus-dialog/cereus-dialog.component';
import { CereusAnnouncementsComponent } from './components/cereus-announcements/cereus-announcements.component';
import { CommonMessageComponent } from '../common/component/common-message/common-message.component';
import { CereusPermissionComponent } from './components/cereus-permission/cereus-permission.component';
import { CereusCommonTabComponent } from './components/cereus-common-tab/cereus-common-tab.component';

@NgModule({
  declarations: [
    // Components
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    TaskManagementComponent,
    LetterHeadComponent,
    HistoryComponent,
    DocumentTabComponent,
    SingleBarChartComponent,
    CereusDialogComponent,
    CereusAnnouncementsComponent,
    CommonMessageComponent,
    CereusPermissionComponent,
    CereusCommonTabComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // BrowserAnimationsModule,
    DropDownsModule,
    GridModule,
    InputsModule,
    LayoutModule,
    IntlModule,
    IconsModule,
    DialogsModule,
    PDFModule,
    ExcelModule,
    DateInputsModule,
    UploadsModule,
    NotificationModule,
    PagerModule,
    ChartModule,
    ChartsModule,
    TooltipsModule,
    TreeViewModule,
    LabelModule,
    NgxSpinnerModule,
    CustomDatePipeModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    TaskManagementComponent,
    LetterHeadComponent,
    FormsModule,
    IconsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    DocumentTabComponent,
    DropDownsModule,
    GridModule,
    InputsModule,
    LayoutModule,
    IntlModule,
    DialogsModule,
    PDFModule,
    ExcelModule,
    DateInputsModule,
    UploadsModule,
    NotificationModule,
    PagerModule,
    NgxDropzoneModule,
    TooltipsModule,
    TreeViewModule,
    ChartModule,
    ChartsModule,
    HistoryComponent,
    LabelModule,
    SingleBarChartComponent,
    NgxSpinnerModule,
    CustomDatePipeModule,
    CereusDialogComponent,
    CereusAnnouncementsComponent,
    CommonMessageComponent,
    CereusPermissionComponent,
    CereusCommonTabComponent,
  ]
})
export class SharedModule { }
