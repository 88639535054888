import { Component, ElementRef, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, fromEvent, merge, Observable } from 'rxjs';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { LoginFormMessages } from 'src/app/shared/CommonValidation/form-validators-messages';
import { GenericValidator } from 'src/app/shared/CommonValidation/generic-validator';
import { UsernameValidator } from 'src/app/shared/CommonValidation/username.validator';
import {
  NotificationService
} from '@progress/kendo-angular-notification';
import { UserManagementService } from '../../auth/administration/user-management/user-management.service';
import { StorageService } from '../../../shared/storage.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthTransaction, OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { PermissionService } from '../../auth/administration/permission/permission.service';
import { LoginServices } from 'src/app/providers/core-helper/core-helper.classes';
import { AnnouncementService } from '../../auth/administration/announcement/announcement.service';
import { UserDomainType } from 'src/app/common/enum/user-domain';
import { CommonNotificationService } from 'src/app/common/service/common-notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})

export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  fieldTextType: boolean = false;
  currentYear: number = new Date().getFullYear();
  isAppMFAOn = (environment.enableEnvironment2FA === "1");

  constructor(private fb: FormBuilder,
    private router: Router,
    public _userManagementService: UserManagementService,
    public storageService: StorageService,
    private _coreHelper: CoreHelperService,
    private _commonNotificationService: CommonNotificationService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public authService: OktaAuthStateService,
    private permissionService: PermissionService,
    private _announcementService: AnnouncementService) {
    this.genericValidator = new GenericValidator(LoginFormMessages);
  }

  maintenanceMessages: any[] = [];
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements!: ElementRef[];
  submitted = false;
  displayMessage: { [key: string]: string } = {};
  public genericValidator: GenericValidator;

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(this._coreHelper.EmailPattern), UsernameValidator.cannotContainSpace]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(this._coreHelper.Passwordpattern)]],
    });

    this.getMaintenanceMessages();
  }

  ngAfterViewInit(): void {
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    merge(this.loginForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(500)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm, this.submitted);
    });
  }

  getMaintenanceMessages() {
    this._announcementService.getMaintenanceMessages().subscribe((Response: any) => {
      if (Response.apiStatus) {
        this.maintenanceMessages = Response.apiData;
      } else {
        this.maintenanceMessages = [];
      }
    });
  }

  async signInUser() {
    localStorage.clear();

    if (this.loginForm.invalid) {
      this.submitted = true;
      this.displayMessage = this.genericValidator.processMessages(this.loginForm, this.submitted);
      return;
    }

    let logindata = this.loginForm.value;
    let username = logindata.email;
    let password = logindata.password;

    if (this.isAppMFAOn) {
      let requestParam: any = { 'userName': username, 'userPassword': password, 'isPinOnly': '0' };
      this._userManagementService.doLoginWithPin(requestParam).subscribe(res => {
        if (res.apiStatus) {
          var userPinInfo = new LoginServices();
          userPinInfo.pinUserId = res.apiData.userId;
          userPinInfo.pinUserName = requestParam.userName;
          userPinInfo.pinUserPswd = requestParam.userPassword;
          this._coreHelper.SetLoginServiceInformation(userPinInfo);
          this.router.navigate(['/non-auth/user-verification']);
        }
      });
    }
    else {
      //Redirecting to OKTA
      await this.oktaAuth.signInWithCredentials({ username, password }).then(oktaResponse => {
        localStorage.clear();
        this.oktaAuthenticationReponse(oktaResponse)

      }).catch(Exception => {
        this._commonNotificationService.displayError('Please enter valid username and password. Or Invalid user info please contact system admin.');
      })
    }
  }

  private oktaAuthenticationReponse(oResponse: AuthTransaction): void {
    if (oResponse.status !== 'SUCCESS') {
      throw new Error(`We cannot handle the ${oResponse.status} status`);
    }
    this._coreHelper.passHeaderUserName(oResponse.user['profile'].firstName + ' ' + oResponse.user['profile'].lastName);

    let oktaUserTokenId = oResponse.user['id'];
    localStorage.setItem('oktaToken', oktaUserTokenId);

    let userName = oResponse.user['profile'].login;
    this.getLoggedInUserDetail(userName, oktaUserTokenId, oResponse)
  }

  getLoggedInUserDetail = (userName: any, oktaUserTokenId: any, oResponse: AuthTransaction) => {
    let loginModel: any = {
      'userName': userName,
      'OktaTokenId': oktaUserTokenId,
      'isLoginRequest': true
    }
    this._userManagementService.getLoginDetailByUserName(loginModel).subscribe(response => {
      if (response.apiStatus) {
        var data = response.apiData.userMasterDetail;
        data.isHideFacility = (data.domainId == UserDomainType.External);
        localStorage.removeItem('token');
        localStorage.setItem('token', data.token);
        
        //User Information
        localStorage.removeItem('cereus-storage');
        localStorage.setItem('cereus-storage', this._coreHelper.GetEncodedValue(JSON.stringify(data)));

        //User Menus Permissions
        let menuData = response.apiData.userPermissionMenus;
        let encodedText = this._coreHelper.GetEncodedValue(JSON.stringify(menuData));
        localStorage.removeItem('cereus-token-storage');
        localStorage.setItem('cereus-token-storage', encodedText);

        //User Full Permissions
        let permissionData = response.apiData.userPermissionList;
        let permissionEncoded = this._coreHelper.GetEncodedValue(JSON.stringify(permissionData));
        localStorage.removeItem('cereus-perm-storage');
        localStorage.setItem('cereus-perm-storage', permissionEncoded);

        if (data.isAgreed == 0) {
          this.oktaAuth.session.setCookieAndRedirect(oResponse.sessionToken, environment.userAgreementUrl);
        }
        else {
          if (data.domainId === UserDomainType.Internal) {
            if (data.roleName === 'Executive')
              this.oktaAuth.session.setCookieAndRedirect(oResponse.sessionToken, environment.executiveWebUrl);
            else
              this.oktaAuth.session.setCookieAndRedirect(oResponse.sessionToken, environment.webUrl);
          } else {
            this.oktaAuth.session.setCookieAndRedirect(oResponse.sessionToken, environment.facilityWebUrl);
          }
        }
      }
    })
  }

  onReset(): void {
    this.submitted = false;
    this.loginForm.reset();
  }

  toggleClass() {
    this.fieldTextType = !this.fieldTextType;
  }
}