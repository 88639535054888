export enum ModuleFeatureIds {
    FacilityMaster_AddFacility = 1,
    FacilityMaster_AddGroup = 2,
    CarrierMaster_AddCarriers = 3,
    PatientMaster_AddPatient = 4,
    DocumentVault_Upload = 5,
    DocumentVault_FileSwitch = 6,
    DocumentVault_Filter = 7,
    Census_AddPatient = 8,
    Census_VOBRequest = 9,
    Census_AuthRequest = 10,
    Census_Confirm = 11,
    Census_ReadOnlyAccess = 12,
    VOB_RequestVOB = 13,
    VOB_ReverifyRequested = 14,
    VOB_AddVOB = 18,
    UR_RequestUR = 15,
    UR_RequestSingleCaseAgreement = 16,
    UR_AddConcurrent = 17,
    Billing_FinishBilling = 21,
    Billing_BillingSync = 22,
    Payments_ManageERAs = 23,
    Payments_AddTask = 24,
    TPN_AddNegotiation = 25,
    TPN_AddReconsideration = 26,
    CollectionGoal_AddGoal = 27,
    Announcement_AddAnnouncement = 28,
    DiagnosticCode_AddDiagnosticCode = 29,
    DocumentWorkflow_AddDocumentWorkflow = 30,
    FacilityDocuments_AddFacilityDocument = 31,
    ServiceMaster_AddService = 32,
    ServiceTypeMaster_AddServiceType = 33,
    FacilityIdentifier_AddFacilityIdentifier = 34,
    ThirdPartyMaster_AddTPN = 35,
    PaymentTask_AddPaymentTask = 36,
    UserManagement_AddDepartment=37,
    UserManagement_AddUsers=38,
    UserManagement_AddRoles=39,
    UserManagement_AddTeams=40,
}


/*

Use this query to get list of Enum from DB.

select smm.ModuleMasterId, 
    concat(REPLACE(smm.ModuleName,' ',''),'_',REPLACE(mfm.FeatureName,' ',''),'=', met.ModuleFeatureMasterId,',')
from SectionModuleMaster as smm
    join ModuleElementTrans as met
        on met.ModuleMasterId = smm.ModuleMasterId
    join ModuleFeatureMaster as mfm
        on mfm.ModuleFeatureMasterId = met.ModuleFeatureMasterId
Order by met.ModuleElementTransId

*/