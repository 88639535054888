import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
@Injectable({ providedIn: 'root' })

export class CommonNotificationService {
    constructor(private notificationService: NotificationService,
    ) {

    }
    displayError(message: string) {
        this.notificationService.show({
            content: message,
            animation: {
                type: 'fade',
                duration: 500
            },
            type: { style: 'error', icon: true },
            position: {
                horizontal: 'right',
                vertical: 'bottom'
            }
        });
    }

    displaySuccessMessage(message: string) {
        this.notificationService.show({
            content: message,
            animation: {
                type: 'slide',
                duration: 500
            },
            type: { style: 'success', icon: true },
            position: {
                horizontal: 'right',
                vertical: 'bottom'
            }
        });
    }
}