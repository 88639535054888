import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { CompositeFilterDescriptor, filterBy, process, State } from "@progress/kendo-data-query";
import { documentsService } from 'src/app/modules/auth/services/document/document.services';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, ValidatorFn, FormControlName, ValidationErrors, AbstractControl } from '@angular/forms';
import { GenericValidator } from 'src/app/shared/CommonValidation/generic-validator';
import { HeaderService } from 'src/app/shared/components/header/header.service';
import { taskManagementMessages } from 'src/app/shared/CommonValidation/form-validators-messages';
import { debounceTime, fromEvent, merge, Observable } from 'rxjs';
import { DialogRef, DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import * as moment from 'moment';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { Router } from '@angular/router';
import { VOBService } from 'src/app/modules/auth/services/vob.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-task-management',
  templateUrl: './task-management.component.html'
})
export class TaskManagementComponent implements OnInit {
  @ViewChild(GridComponent) taskGridList: GridComponent;
  taskViewList: any[] = [];
  taskViewList1: any[] = [];
  taskListData: any[] = [];
  selectTaskType: any[] = [];
  selectFacility: any[] = [];
  selectCaseId: any[] = [];
  selectUser: any[] = [];
  filePath: string = '';
  fileName: string = '';
  isfileEdit: boolean = false;
  taskManagementTitle = 'Add Task';
  public Mode: string = '';
  public addTask: boolean = false;
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements!: ElementRef[];
  public taskManagementForm!: FormGroup;
  fileFormData: FormData = new FormData();
  public submitted = false;
  displayMessage: { [key: string]: string } = {};
  private genericValidator: GenericValidator;
  public taskId: number = 0;
  public todate = new Date();
  public info: any;
  public isViewTask: boolean = false;
  disabled: boolean = false;
  lastClickedRowItem;
  documentTaskManagment = false
  vobTaskManagment = false
  authorisationTaskManagment = false
  billingTaskManagment = false
  collectionTaskManagment = false
  paymentTaskManagment = false
  tpnTaskManagment = false
  refundTaskManagment = false

  constructor(public _serviceDocument: documentsService,
    private dialogService: DialogService,
    public _vobService: VOBService,
    private fb: FormBuilder,
    public _headerService: HeaderService,
    private router: Router,
    public _coreHelper: CoreHelperService) {
    this.genericValidator = new GenericValidator(taskManagementMessages);
    this.exportAllTask = this.exportAllTask.bind(this);

    const currentUrl = this.router.url
    if (currentUrl.includes('document')) {
      this.documentTaskManagment = true
    } else if (currentUrl.includes('vob')) {
      this.vobTaskManagment = true
    } else if (currentUrl.includes('authorization')) {
      this.authorisationTaskManagment = true
    } else if (currentUrl.includes('billing')) {
      this.billingTaskManagment = true
    } else if (currentUrl.includes('collection')) {
      this.collectionTaskManagment = true
    } else if (currentUrl.includes('payment')) {
      this.paymentTaskManagment = true
    } else if (currentUrl.includes('tpn')) {
      this.tpnTaskManagment = true
    } else if (currentUrl.includes('refund')) {
      this.refundTaskManagment = true
    }

    this._vobService.callTaskClear.subscribe((res: any) => {
      this.clearTaskmanagement();
    })
    this._vobService.exportExcel.subscribe((res: any) => {
      this.exportExcel(this.taskGridList);
    })
    this._vobService.exportPdf.subscribe((res: any) => {
      this.exportPdf(this.taskGridList);
    })
  }

  ngOnInit(): void {
    this.getTaskManagementList(0);
    this.getTaskType();
    this.getFacility();
    this.getAssignUser();
    this.getStatusName();
    this.defaultTaskManagementForm();
  }

  ngAfterViewInit() {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements.map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));
    // Merge the blur event observable with the valueChanges observable
    merge(this.taskManagementForm.valueChanges, ...controlBlurs)
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.displayMessage = this.genericValidator.processMessages(
          this.taskManagementForm,
          this.submitted
        );
      });
  }

  public state: State = {
    skip: 0,
    take: 10,
  };

  public taskModelOpen() {
    this.addTask = true;

  }
  public taskModelClose() {
    this.addTask = false;
  }

  kendoClick(e) {
    this.lastClickedRowItem = e.dataItem;
  }

  getTaskManagementList(taskId: any) {
    let param = {
      facilityId: this._coreHelper.getFacilityId()
    };
    this._serviceDocument.gettaskManagementList(param).subscribe((response: any) => {
      if (response.apiStatus) {
        if (this.router) {
          this.taskViewList = []
          if (response.apiStatus) {
            this.taskViewList = response.apiData;
          }
        }
      }
    });
  }

  defaultTaskManagementForm() {
    this.taskManagementForm = this.fb.group({
      TaskId: [0],
      taskDate: [new Date()],
      taskTypeId: ['', [Validators.required]],
      caseId: [null, [Validators.required]],
      facilityId: [this._coreHelper.isHideFacility() ? this._coreHelper.getFacilityId() : '', [Validators.required]],
      assignedTo: ['', [Validators.required]],
      roleId: [0, [Validators.required]],
      dueDate: ['', [Validators.required]],
      taskSubject: ['', [Validators.required, Validators.maxLength(50)]],
      details: ['', [Validators.required, Validators.maxLength(100)]],
      statusId: ['', [Validators.required]],
      notifyMe: [1], 
      filePath: [''],
      fileName :[''],
      isArchive: [0],
      CreatedBy: [this._coreHelper.getUserId()],
      SpMode: [0, [Validators.required]],
    })
  }

  public addupdateTaskInfo() {
    if (this.taskManagementForm.invalid) {
      this.submitted = true;
      this.displayMessage = this.genericValidator.processMessages(this.taskManagementForm, this.submitted);
      return;
    }
    let data = this.taskManagementForm.value;
    //let date = this.pipe.transform(data.taskDate, 'MM/dd/yyyy');
    this.fileFormData.set("taskId", data.taskId);
    // this.fileFormData.set("taskDate", date!= null ? date.toString() : "");
    this.fileFormData.set("taskDate", data.taskDate.toLocaleString());
    this.fileFormData.set("taskTypeId", data.taskTypeId);
    this.fileFormData.set("caseId", data.caseId);
    this.fileFormData.set("facilityId", data.facilityId);
    this.fileFormData.set("assignedTo", data.assignedTo);
    this.fileFormData.set("dueDate", data.dueDate.toLocaleString());
    this.fileFormData.set("taskSubject", data.taskSubject);
    this.fileFormData.set("details", data.details);
    this.fileFormData.set("statusId", data.statusId);
    this.fileFormData.set("notifyMe", data.notifyMe == true ? "1": "0");
    this.fileFormData.set("filePath", data.filePath == null ? null : data.filePath[0]);
    this.fileFormData.set("taskTimeLine", data.taskTimeLine);
    this.fileFormData.set("roleId", data.roleId);
    this.fileFormData.set("createdBy", this._coreHelper.getUserId().toString()); //its 1 is static. But lator on it should be dynamic
    this.fileFormData.set("isArchive", data.isArchive);
    this.fileFormData.set("spMode", data.spMode);
    this.fileFormData.set("spMode", "1");
    
    if (this.taskId > 0) {
      this.fileFormData.set('taskId', this.taskId.toString());
      this.fileFormData.set('spMode', "1");
    }
    else {
      this.fileFormData.set('taskId', "0");
      this.fileFormData.set('spMode', "0");
    }

    this._headerService.AddUpdateTaskMaster(this.fileFormData).subscribe(response => {
      if (response.apiMessage = true) {
        this.addTask = false;
        this.submitted = false;
        this.getTaskManagementList(0);
      }
    });
  }

  public getTaskType() {
    let taskType: any = { 'taskTypeId': 0 }
    this._headerService.gettaskTypeList(taskType).subscribe(response => {
      if (response.apiStatus) {
        this.selectTaskType = response.apiData?.taskTypeList;
      }
    });
  }

  public getFacility() {
    this._headerService.getfacilityList().subscribe(response => {
      if (response.apiStatus) {
        this.selectFacility = response.apiData;
      }
    });
  }

  public getAssignUser() {
    // let user: any = { 'facilityMasterId': 0 }
    this._headerService.getassignUserList(0).subscribe(response => {
      if (response.apiStatus) {
        this.selectUser = response.apiData;
        this.selectUser.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
      }
    });
  }

  public disabledDates = (date: Date): boolean => {
    date.setDate(date.getDate() + 1);
    return date < this.todate;
  }

  public checkIsCaseExists($event: any) {
    let facilityId = $event.facilityMasterId;
    this._headerService.getcaseIdList(facilityId).subscribe(response => {
      if (response.apiStatus) {
        this.selectCaseId = response.apiData;
      }
      else {
        // alert('select facility which have case id')
        const dialog: DialogRef = this.dialogService.open({
          cssClass: "kendo-modal modal-sm text-center",
          title: 'Note',
          content: 'select facility which have case id',
          actions: [
            { text: 'OK', themeColor: "primary" }
          ],
        });
        this.selectCaseId = [];
        this.taskManagementForm.controls['facilityId'].reset();
      }
    });
  }

  public selectStatus = [{
    status: "On Hold",
    statusId: "1"
  }, {
    status: "In Progress",
    statusId: "2"
  }, {
    status: "Due",
    statusId: "3"
  }, {
    status: "Completed",
    statusId: "4"
  }];

  public getStatusName() {
    let statusId: any = { 'statusId': 0 }
    this._headerService.getstatusList(statusId).subscribe(response => {
      if (response.apiStatus) {
        this.selectStatus = response.apiData?.taskStatusList;
      }
    });
  }

  viewTaskDetails(taskId: number) {
    this.addTask = true;
    this.taskId = taskId;
    this.taskManagementTitle = 'View Task';
    this.Mode = 'View';
    this.getEditedTask(this.taskId);
  }

  public editTaskDetails(taskId: number) {
    if (this._coreHelper.isNullOrUndefined(taskId)) {
      this.taskId = this.lastClickedRowItem.taskId;
    }
    else {
      this.taskId = taskId;
    }
    this.addTask = true;
    this.taskManagementTitle = 'Edit Task';
    this.Mode = 'Edit';
    this.getEditedTask(this.taskId);
  }

  getEditedTask(taskId) {

    let param = { taskId: taskId }
    this._serviceDocument.getEditedTaskList(param).subscribe((response: any) => {
      if (response.apiStatus == true) {
        this.info = response.apiData[0];
        let taskDate = new Date(moment(this.info.taskDate).local().format('MM/DD/YYYY').toString());
        let dueDate = new Date(moment(this.info.dueDate).local().format('MM/DD/YYYY').toString());
        this.taskManagementForm.patchValue({
          taskDate: taskDate,
          taskTypeId: this.info.taskTypeId,
          facilityId: this.info.facilityId,
          caseId: this.info.caseId,
          assignedTo: this.info.assignedTo,
          dueDate: dueDate,
          taskSubject: this.info.taskSubject,
          details: this.info.details,
          statusId: this.info.statusId,
          notifyMe: this.info.notifyMe,
          filePath: this.info.filePath,
         
        });
      }
      if (taskId > 0) {
        this.info = this.taskViewList.find((t: { taskId: any; }) => t.taskId == taskId);
        //let caseId = this.selectCaseId[0].caseId;

        //this.selectCaseId[0].caseId;
        // this.setCaseId(this.info.caseId);

        if (this.Mode == 'View') { 
          this._headerService.getcaseIdList(this.info.facilityId).subscribe(response => {
            if (response.apiStatus) {
              this.selectCaseId = response.apiData;
              this.taskManagementForm.controls['caseId'].setValue(Number(this.info.caseId));
            }
          });
          
          this.taskManagementForm.disable();
          this.disabled = true;
        }
        if (this.Mode == 'Edit') {
          this.isfileEdit = true;
          this.fileName = this._coreHelper.isNullOrUndefined(this.info.fileName) ? null : this.info.fileName;
          this.filePath = this._coreHelper.isNullOrUndefined(this.info.filePath) ? null : this.info.filePath;
          this._headerService.getcaseIdList(this.info.facilityId).subscribe(response => {
            if (response.apiStatus) {
              this.selectCaseId = response.apiData;
              this.taskManagementForm.controls['caseId'].setValue(Number(this.info.caseId));
            }
          });
        }
      }
    });
  }

  public showConfirmation(taskId: number) {
    const dialog: DialogRef = this.dialogService.open({
      cssClass: "kendo-modal modal-sm text-center",
      title: "Please Confirm",
      content: "Are you sure you want to delete?",
      actions: [{ text: "Yes", themeColor: "primary" }, { text: "No" }],
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text == 'Yes') {

          this.fileFormData.set("taskDate", '1/2/1753');
          this.fileFormData.set("taskTypeId", "0");
          this.fileFormData.set("caseId", "0");
          this.fileFormData.set("facilityId", "0");
          this.fileFormData.set("assignedTo", "0");
          this.fileFormData.set("dueDate", '1/2/1753');
          this.fileFormData.set("taskSubject", "");
          this.fileFormData.set("details", "");
          this.fileFormData.set("statusId", "0");
          this.fileFormData.set("taskTimeLine", "");
          this.fileFormData.set("roleId", "0");
          this.fileFormData.set("createdBy", this._coreHelper.getUserId().toString()); //its 1 is static. But lator on it should be dynamic
          this.fileFormData.set("isArchive", "0");
          this.fileFormData.set('taskId', taskId.toString());
          this.fileFormData.set('spMode', "2");

          if (taskId > 0) {
            this._serviceDocument.deleteTaskMaster(this.fileFormData).subscribe(response => {
              if (response.apiMessage = 'Success') {
                this.getTaskManagementList(0);
              }
            });
          }
        }
      }
    });
  }


  getHourByMinute = (min) => {
    let hour = Math.floor(min / 60); //1h
    if (this._coreHelper.isNullOrUndefined(hour)) {
      return min + ' Minute'
    }
    else {
      return hour + ' Hour'
    }
    return hour
  }
  public exportAllTask(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.taskViewList,{}).data
    };
    return result;
  }

  public addtpn = false;

  public addtpnOpen() {
    this.addtpn = true;
    this.taskManagementTitle = 'Add Task';
    this.isfileEdit = false;
  }

  public clearTaskmanagement(){
    let sender = this.taskGridList;
  sender.filter.filters = [];
  this.getTaskManagementList(0);
  }
  public exportExcel(taskGridData: GridComponent) {
    taskGridData.saveAsExcel();
  }
  public exportPdf(taskGridData: GridComponent) {
    taskGridData.saveAsPDF();
  }
}
