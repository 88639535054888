export enum ModuleMasterIds {
    InternalDashboard = 1,
    FacilityDashboard = 2,
    ExecutiveDashboard = 3,
    FacilityMaster = 4,
    FacilityInfo = 5,
    CarrierMaster = 6,
    PatientMaster = 7,
    DocumentVault = 8,
    Census = 9,
    VOB = 10,
    UR = 11,
    Billing = 12,
    RCM = 13,
    MedicalRecord = 14,
    Payments = 15,
    TPN = 16,
    CallRecordAnalytics = 17,
    ClaimsBilled = 18,
    PaymentSummary = 19,
    PaymentDetail = 20,
    PatientStatement = 21,
    CurrentPayerBreakdown = 22,
    ARAgingSummary = 23,
    URDailyAuthorization = 24,
    URUpcomingReview = 25,
    ActivitySummary = 26,
    BalanceByPayerPriority = 27,
    PatientBalanceReport = 28,
    PayerTypeBreakdown = 29,
    Permission = 30,
    RCMForAdmin = 31,
    CollectionGoal = 32,
    UserManagement = 33,
    Announcement = 34,
    DiagnosticCode = 35,
    DocumentWorkflow = 36,
    ModuleMaster = 37,
    FacilityDocuments = 38,
    ServiceMaster = 39,
    ServiceTypeMaster = 40,
    FacilityIdentifier = 41,
    MessageConfiguration = 42,
    ThirdPartyMaster = 43,
    Tenuregoals = 44,
    Statistics = 45,
    PaymentTask = 46,
    UploadTypeMaster = 47,
}