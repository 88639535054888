import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Modules } from 'src/app/providers/core-role-access-service';
import { CoreHelperService } from '../../../providers/core-helper/core-helper.service';
import { CollectionService } from '../../../modules/auth/services/collection/collection.service';
import { environment } from 'src/environments/environment';
import { ApplicationMenuSections } from 'src/app/common/enum/app-menu-sections';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  sidebarToggle = false;
  sidebarToggleMobile = false;
  userId: number;
  facilityId: number;
  public Modules;
  title: string = '';
  reportModuleString = '';
  adminModuleString = '';
  resourceModuleString = '';
  userInfo: any;
  activeModuleName: string = "";
  versionText: string = environment.versionText;
  userDomain: number = 0;

  //#region Dynamic Menus
  userPermissionMenu: any = [];
  sectionModule: any = [];
  resourceModuleList: any = [];
  serviceModuleList: any = [];
  reportModuleList: any = [];
  adminModuleList: any = [];
  isResourceFound: boolean = false;
  isServicesFound: boolean = false;
  isReportsFound: boolean = false;
  isAdministrationFound: boolean = false;
  //#endregion

  constructor(
    private router: Router,
    public coreHelperService: CoreHelperService,
    public collectionService: CollectionService) { }


  ngOnInit(): void {
    this.userDomain = this.coreHelperService.getUserDomain();
    this.userInfo = this.coreHelperService.getLoggedInUserDetail()
    this.userId = this.coreHelperService.getUserId();
    this.facilityId = this.coreHelperService.getFacilityId();

    //Loading Dynamic Menus
    if (localStorage.getItem("cereus-token-storage") != undefined || localStorage.getItem("cereus-token-storage") != undefined) {
      let userPermissionValue = localStorage.getItem("cereus-token-storage");
      this.userPermissionMenu = this.coreHelperService.GetDecodedValue(userPermissionValue);
      if (this.userPermissionMenu != "[]" && this.userPermissionMenu.length > 0) {
        let menusList = JSON.parse(this.userPermissionMenu);
        // this.menuSection = [
        //   ...new Map(menusList.map((item) => [item["sectionName"], item])).values(),
        // ];
        this.sectionModule = [
          ...new Map(menusList.map((item) => [item["moduleName"], item])).values(),
        ];
        this.resourceModuleList = this.sectionModule.filter(r => r.sectionMasterId === ApplicationMenuSections.Resource);
        this.serviceModuleList = this.sectionModule.filter(r => r.sectionMasterId === ApplicationMenuSections.Services);
        this.reportModuleList = this.sectionModule.filter(r => r.sectionMasterId === ApplicationMenuSections.Reports);
        this.adminModuleList = this.sectionModule.filter(r => r.sectionMasterId === ApplicationMenuSections.Administrations);

        this.isResourceFound =  (this.resourceModuleList != undefined && this.resourceModuleList.length > 0);
        this.isServicesFound =  (this.serviceModuleList != undefined && this.serviceModuleList.length > 0);
        this.isReportsFound =  (this.reportModuleList != undefined && this.reportModuleList.length > 0);
        this.isAdministrationFound =  (this.adminModuleList != undefined && this.adminModuleList.length > 0);
      }
    }
    this.coreHelperService.sidebarActiveModuleName.subscribe((moduleName: string) => {
      this.activeModuleName = moduleName;
    });
  }

  public get moduleName(): typeof Modules {
    return Modules;
  }

  isMultipleFaility = () => {
    return this.userInfo.isMultipleFacility;
  }

  setTitle(title: string, isDashboard: boolean = false) {
    this.collectionService.isFromBack = false;
    localStorage.setItem('title', title)
    this.title = title;
    if (isDashboard) {
      this.coreHelperService.setSidebarModuleName("");
      $('#sidebarAccordion .collapse').collapse('hide');
    }
  }

  redirect() {
    if ((this.coreHelperService.getLoggedInUserDomain() === 0) && !(this.coreHelperService.getLoggedInUserDetail()?.roleName == 'Executive')) {
      this.router.navigate(['/auth/dashboard'])
    } else if (this.coreHelperService.getLoggedInUserDomain() === 1) {
      this.router.navigate(['/auth/facility-dashboard'])
    } else if (this.coreHelperService.getLoggedInUserDetail()?.roleName == 'Executive') {
      this.router.navigate(['/auth/executive-dashboard'])
    }
  }

  addBodyClass() {
    var test = document.getElementsByTagName('body')
    this.sidebarToggle = !this.sidebarToggle;

    if (!!this.sidebarToggle) {
      test[0].classList.add('nav-toggle')
    } else {
      test[0].classList.remove('nav-toggle')
    }
  }

  addBodyClassMobile() {
    var test1 = document.getElementsByTagName('body')
    this.sidebarToggleMobile = !this.sidebarToggleMobile;

    if (!!this.sidebarToggleMobile) {
      test1[0].classList.add('nav-toggle-mobile')
    } else {
      test1[0].classList.remove('nav-toggle-mobile')
    }
  }


  getRouterLinkForTab(sectionName, moduleName, tabName) {
    if (sectionName == "Resource") {
      if (moduleName == "Facility Master") {
        return "auth/resource/facility/facility-master";
      }
    }
    return ""
  }
}
