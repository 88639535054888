import { Component, OnInit } from '@angular/core';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { AnnouncementService } from 'src/app/modules/auth/administration/announcement/announcement.service';

@Component({
  selector: 'app-cereus-announcements',
  templateUrl: './cereus-announcements.component.html',
  styleUrls: ['./cereus-announcements.component.scss']
})
export class CereusAnnouncementsComponent implements OnInit {

  announcementList: any[] = [];

  constructor(private _announcementService: AnnouncementService) { }

  ngOnInit(): void {
    this.getAnnouncementList();
  }

  async getAnnouncementList() {
    this._announcementService.getAnnouncementsListForDashboard().subscribe(
    {
      next: (Response: any) => {
        if (Response.apiStatus === true) {
          this.announcementList = Response.apiData;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });
  }

}
