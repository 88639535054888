<div class="row align-items-center">
    <div class="text-end">
        <label for="selectAllNone">
            <input type="radio" id="rbAllNone" kendoRadioButton [checked]="false" #AllNone name="ApplyAll"
                (change)="onApplyOnSelected(1)" />
            <kendo-label class="k-radio-label" [for]="AllNone" text="None All"></kendo-label>
        </label>
        <label for="selectAllView">
            <input type="radio" id="rbAllView" kendoRadioButton [checked]="false" #AllView name="ApplyAll"
                (change)="onApplyOnSelected(2)" />
            <kendo-label class="k-radio-label" [for]="AllView" text="View All"></kendo-label>
        </label>
        <label for="selectAllEdit" *ngIf="!this.disableEditOption">
            <input type="radio" id="rbAllEdit" kendoRadioButton [checked]="false" #AllEdit name="ApplyAll"
                (change)="onApplyOnSelected(3)" />
            <kendo-label class="k-radio-label" [for]="AllEdit" text="Edit All"></kendo-label>
        </label>
    </div>
    <div class="col-12 col-md-12 col-xxl-8">
        <ul class="nav nav-tabs common-nav-tab" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button [ngClass]="{'active': activeTab == this.dashboardTab}" class="nav-link w-100" id="Dashboard-tab"
                    data-bs-toggle="tab" data-bs-target="#Dashboard" (click)="onTabChanged(this.dashboardTab)"
                    type="button" role="tab" aria-Dashboard="Dashboard" aria-selected="true">
                    Dashboard
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button [ngClass]="{'active': activeTab == this.resourceTab}" class="nav-link w-100" id="Resource-tab"
                    data-bs-toggle="tab" data-bs-target="#Resource" (click)="onTabChanged(this.resourceTab)"
                    type="button" role="tab" aria-Resource="Resource" aria-selected="false">
                    Resource
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button [ngClass]="{'active': activeTab == this.serviceTab}" class="nav-link w-100" id="Services-tab"
                    data-bs-toggle="tab" data-bs-target="#Services" (click)="onTabChanged(this.serviceTab)"
                    type="button" role="tab" aria-Services="Services" aria-selected="false">
                    Services
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button [ngClass]="{'active': activeTab == this.reportsTab}" class="nav-link w-100" id="Reports-tab"
                    data-bs-toggle="tab" data-bs-target="#Reports" (click)="onTabChanged(this.reportsTab)" type="button"
                    role="tab" aria-Reports="Reports" aria-selected="false">
                    Reports
                </button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="this.isSuperAdmin">
                <button [ngClass]="{'active': activeTab == this.administrationTab}" class="nav-link w-100"
                    id="Administration-tab" data-bs-toggle="tab" (click)="onTabChanged(this.administrationTab)"
                    data-bs-target="#Administration" type="button" role="tab" aria-Administration="Administration"
                    aria-selected="false">
                    Administration
                </button>
            </li>
        </ul>
    </div>

    <div class="tab-content">
        <div class="g-page-layout__large-box-with-border company-box scrollable" style="margin-top:1%;" #scroll>
            <div class="custom-role-outer scrollable">
                <div class="custom-role-inner">
                    <div class="tree-header">
                        <table>
                            <tr>
                                <th width="70%"><span>Access Level</span></th>
                                <th width="10%"><span>None</span></th>
                                <th width="10%"><span *ngIf="this.activeTab == 4">View Only</span>
                                    <span *ngIf="this.activeTab != 4">View</span></th>
                                <th width="10%"><span *ngIf="this.activeTab == 4">View & Export</span>
                                    <span *ngIf="this.activeTab != 4">Edit</span>
                                </th>
                            </tr>
                        </table>
                        <div style="float: right; margin-top: -2%; margin-right: 1%;">
                            <button kendoButton (click)="expand()"
                                class="btn btn-primary-outline btn-sm">Expand</button>
                            <button kendoButton (click)="collapse()"
                                class="btn btn-primary-outline btn-sm">Collapse</button>
                        </div>
                    </div>
                    <div>
                        <kendo-treeview #tree [nodes]="permissionData" 
                            cssClass="scrollable" 
                            textField="text"
                            childrenField="items"
                            [children]="children" 
                            [hasChildren]="hasChildren" 
                            [expandBy]="'text'"
                            [(expandedKeys)]="expandedKeys" 
                            (nodeClick)="onNodeClick($event)" 
                            kendoTreeViewExpandable kendoTreeViewHierarchyBinding>
                            <ng-template #nodeTemplate kendoTreeViewNodeTemplate let-dataItem>
                                <table class="treeview-wrapper">
                                    <tr>
                                        <td width="70%">
                                            <div class="ename">
                                                {{dataItem.text}}
                                            </div>
                                            <!-- {{dataItem.isNone}} | {{dataItem.isView}} | {{dataItem.isEdit}} -->
                                        </td>
                                        <td width="10%">
                                            <input type="radio" kendoRadioButton
                                                id="{{dataItem.value.isNone}}-noneSelect"
                                                name="{{dataItem.value.sectionMasterId}}{{dataItem.value.moduleMasterId}}{{dataItem.value.tabMasterId}}{{dataItem.value.tabCardMasterId}}{{dataItem.value.moduleElementTransId}}"
                                                [hidden]="dataItem.isNone == 9"
                                                [checked]="dataItem.isNone == 1"
                                                (change)="onPermissionSelected(dataItem.value, 1)" />
                                        </td>
                                        <td width="10%">                                            
                                            <input type="radio" kendoRadioButton 
                                                id="{{dataItem.value.isView}}-viewSelect"
                                                name="{{dataItem.value.sectionMasterId}}{{dataItem.value.moduleMasterId}}{{dataItem.value.tabMasterId}}{{dataItem.value.tabCardMasterId}}{{dataItem.value.moduleElementTransId}}"
                                                [checked]="dataItem.isView == 1"
                                                [value]="dataItem.value"
                                                (change)="onPermissionSelected(dataItem.value, 2)"
                                                [hidden]="dataItem.isView == 9"
                                                [attr.disabled]="dataItem.isView == 2 ? '' : null" 
                                                [disabled]="dataItem.isView == 2" />
                                        </td>
                                        <td width="10%">
                                            <input type="radio" kendoRadioButton 
                                                id="{{dataItem.value.isEdit}}-editSelect"
                                                name="{{dataItem.value.sectionMasterId}}{{dataItem.value.moduleMasterId}}{{dataItem.value.tabMasterId}}{{dataItem.value.tabCardMasterId}}{{dataItem.value.moduleElementTransId}}"
                                                [checked]="dataItem.isEdit == 1" 
                                                [value]="dataItem.value"                                                 
                                                (change)="onPermissionSelected(dataItem.value, 3)"
                                                [hidden]="dataItem.isEdit == 9"
                                                [disabled]="dataItem.isEdit == 2"
                                                [attr.disabled]="dataItem.isEdit == 2 ? '' : null"  />
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                        </kendo-treeview>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-cereus-dialog *ngIf="this.showSaveConfirmation" [showConfirmDialog]="true"
    [dialogBodyMessage]="'You have Unsaved changes would you like to save it?'" 
    [yesButtonText]="'Yes'"
    [noButtonText]="'No'" 
    (noButtonClickEvent)="discardChanges()"
    (yesButtonClickEvent)="saveUnsaveChanges()">
</app-cereus-dialog>