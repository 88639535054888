import { Component, Input, Output, OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PermissionService } from 'src/app/modules/auth/administration/permission/permission.service';
import { lastValueFrom } from 'rxjs';
import { PermissionType } from 'src/app/common/enum/permission-type';
import { ApplicationMenuSections } from 'src/app/common/enum/app-menu-sections';
import { NodeClickEvent } from '@progress/kendo-angular-treeview';

@Component({
  selector: 'app-cereus-permission',
  templateUrl: './cereus-permission.component.html',
  styleUrls: ['./cereus-permission.component.scss']
})

export class CereusPermissionComponent implements OnInit, AfterViewInit {

  @Input() isSuperAdmin: boolean = false;
  @Input() setUserPermissionList: any[] = [];
  @Input() setRolePermissionList: any[] = [];
  @Input() workOnRole: boolean = false;
  @Input() workOnUser: boolean = false;

  @Output() yesButtonClickEvent = new EventEmitter<void>();

  dashboardTab: ApplicationMenuSections = ApplicationMenuSections.Dashboard;
  resourceTab: ApplicationMenuSections = ApplicationMenuSections.Resource;
  serviceTab: ApplicationMenuSections = ApplicationMenuSections.Services;
  reportsTab: ApplicationMenuSections = ApplicationMenuSections.Reports;
  administrationTab: ApplicationMenuSections = ApplicationMenuSections.Administrations;

  activeTab: ApplicationMenuSections;
  previousSelectedTab: number = 0;
  public permissionData: any[];
  public children = (dataItem: any): Observable<any[]> => of(dataItem.items);
  public hasChildren = (dataItem: any): boolean => !!dataItem.items;
  public allParentNodes = [];
  public expandedKeys: any[] = [];
  // isChecked: boolean = false;
  hasPermissionUpdate: boolean = false;
  showSaveConfirmation: boolean = false;
  disableEditOption: boolean = false;
  featureAccessValue: number = 9;
  disabledId: number = 2;
  noneAll: number = 1;
  viewAll: number = 2;
  editAll: number = 3;
  allowed: number = 1;
  notAllowed: number = 0;

  ReportPermissionTitle: string = "View/Export";
  EditPermissionTitle: string = "Edit";
  
  constructor(private _permissionService: PermissionService,
  ) { }

  ngOnInit(): void {
    this.activeTab = ApplicationMenuSections.Dashboard;
    this.getPermissionList();
  }

  ngAfterViewInit() {
  }

  
  //#region Populate List of Permission by Tab
  async getPermissionList() {
    let tabId = this.activeTab;
    this.disableEditOption = (this.activeTab === ApplicationMenuSections.Dashboard || this.activeTab === ApplicationMenuSections.Reports);
    await lastValueFrom(this._permissionService.getPermissionByTab(tabId)).then((response) => {
      if (response.apiStatus) {
        this.permissionData = response.apiData.permissionTree;
        this.permissionData.forEach((node1: any) => {
          let currentObject = node1;

          //Level# 1
          if (node1.value != undefined || node1.value != null) {
            this.mapThePermission(currentObject);
            node1.isNone = currentObject.value.isNone;
            node1.isView = currentObject.value.isView;
            node1.isEdit = currentObject.value.isEdit;

            //Level# 2
            if (node1.items != undefined || node1.items != null && node1.items.length > 0) {
              node1.items.forEach((node2: any) => {
                let node2_items = node2;
                this.mapThePermission(node2_items);
                node2.isNone = node2_items.value.isNone;
                node2.isView = node2_items.value.isView;
                node2.isEdit = node2_items.value.isEdit;
                if(node2.text === 'Features'){
                  node2.isNone = this.featureAccessValue;
                  node2.isView = this.featureAccessValue;
                  node2.isEdit = this.featureAccessValue
                }

                //Level# 3
                if (node2.items != undefined || node2.items != null && node2.items.length > 0) {
                  node2.items.forEach((node3: any) => {
                    let node3_items = node3;
                    this.mapThePermission(node3_items);
                    node3.isNone = node3_items.value.isNone;
                    node3.isView = node3_items.value.isView;
                    node3.isEdit = node3_items.value.isEdit;

                    //Level# 4
                    if (node3.items != undefined || node3.items != null && node3.items.length > 0) {
                      node3.items.forEach((node4: any) => {
                        let node4_items = node4;
                        this.mapThePermission(node4_items);
                        node4.isNone = node4_items.value.isNone;
                        node4.isView = node4_items.value.isView;
                        node4.isEdit = node4_items.value.isEdit;
                      });
                    }
                  });
                }
              });
            }
          }
        });
        this.getAllParentTextProperties(this.permissionData);
      }
    });
  }

  mapThePermission(treeNode: any): any {
    let nodeValue = treeNode.value;
    //Load Role Permission
    if (this.workOnRole && this.setRolePermissionList.length > 0) {
      this.setRolePermissionList.forEach((role) => {
        if (nodeValue.sectionMasterId == role.sectionMasterId && nodeValue.moduleMasterId == role.moduleMasterId &&
          nodeValue.tabMasterId == role.tabMasterId && nodeValue.tabCardMasterId == role.tabCardMasterId
          && nodeValue.moduleElementTransId == role.moduleElementTransId
        ) {
          treeNode.value.isNone = role.isNone;
          treeNode.value.isView = role.isView;
          treeNode.value.isEdit = role.isEdit;
          return treeNode;
        }
      });
    }

    //Load User Permission
    if (this.workOnUser && this.setUserPermissionList.length > 0) {
      this.setUserPermissionList.forEach((role) => {
        if (nodeValue.sectionMasterId == role.sectionMasterId && nodeValue.moduleMasterId == role.moduleMasterId &&
          nodeValue.tabMasterId == role.tabMasterId && nodeValue.tabCardMasterId == role.tabCardMasterId
          && nodeValue.moduleElementTransId == role.moduleElementTransId
        ) {
          treeNode.value.isNone = role.isNone;
          treeNode.value.isView = role.isView;
          treeNode.value.isEdit = role.isEdit;
          return treeNode;
        }
      });
    }
  }
  //#endregion


  onTabChanged(tabIndex: number) {
    this.activeTab = tabIndex;    
    if(this.hasPermissionUpdate) {
      this.hasPermissionUpdate = false;
      this.previousSelectedTab = this.activeTab;
      this.showSaveConfirmation = true;
    }else {
      this.hasPermissionUpdate = false;
      this.activeTab = tabIndex;
      this.getPermissionList();
    }

  }

  saveUnsaveChanges() {
    this.yesButtonClickEvent.emit();
    // this.discardChanges();
  }

  discardChanges() {
    this.showSaveConfirmation = false;
    this.hasPermissionUpdate = false;
    this.activeTab = this.previousSelectedTab;
    this.previousSelectedTab = 0;
    this.getPermissionList();
  }

  //#region When user will update the Permission  For Role / User Modules
  onPermissionSelected(permissionObject: any, permissionType: PermissionType) {
    permissionObject.isNone = (permissionType === PermissionType.IsNone) ? this.allowed : this.notAllowed;
    permissionObject.isView = ((permissionObject.isView === this.disabledId) ? this.disabledId : (permissionType === PermissionType.IsView) ? this.allowed : this.notAllowed);
    permissionObject.isEdit = ((permissionObject.isEdit === this.disabledId) ? this.disabledId : (permissionType === PermissionType.IsEdit) ? this.allowed : this.notAllowed);
    this.updatePermissionOnSelectionChanged(permissionObject);
    this.hasPermissionUpdate = true;
  }

  updatePermissionOnSelectionChanged(fromObject: any) {
    if (this.workOnRole){
      this.setRolePermissionList.forEach((role) => {
        if (fromObject.sectionMasterId == role.sectionMasterId && fromObject.moduleMasterId == role.moduleMasterId &&
          fromObject.tabMasterId == role.tabMasterId && fromObject.tabCardMasterId == role.tabCardMasterId
          && fromObject.moduleElementTransId == role.moduleElementTransId
        ) {
          role.sectionMasterId = fromObject.sectionMasterId;
          role.moduleMasterId = fromObject.moduleMasterId;
          role.tabMasterId = fromObject.tabMasterId;
          role.tabCardMasterId = fromObject.tabCardMasterId;
          role.moduleElementTransId = fromObject.moduleElementTransId;
          role.isNone = fromObject.isNone;
          role.isView = (fromObject.moduleElementTransId > 0) ? this.disabledId : fromObject.isView;
          role.isEdit = fromObject.isEdit;
        }
      });
    }
    else if (this.workOnUser){
      this.setUserPermissionList.forEach((role) => {
        if (fromObject.sectionMasterId == role.sectionMasterId && fromObject.moduleMasterId == role.moduleMasterId &&
          fromObject.tabMasterId == role.tabMasterId && fromObject.tabCardMasterId == role.tabCardMasterId
          && fromObject.moduleElementTransId == role.moduleElementTransId
        ) {
          role.sectionMasterId = fromObject.sectionMasterId;
          role.moduleMasterId = fromObject.moduleMasterId;
          role.tabMasterId = fromObject.tabMasterId;
          role.tabCardMasterId = fromObject.tabCardMasterId;
          role.moduleElementTransId = fromObject.moduleElementTransId;
          role.isNone = fromObject.isNone;
          role.isView = fromObject.isView;
          role.isEdit = fromObject.isEdit;
        }
      });
    }
  }
  //#endregion


  //#region Expand and Collapse
  public getAllParentTextProperties(items: Array<any>) {
    items.forEach((i) => {
      if (i.items) {
        this.allParentNodes.push(i.text);
        this.getAllParentTextProperties(i.items);
      }
    });
  }

  public expand() {
    this.expandedKeys = this.allParentNodes.slice();
  }

  public collapse() {
    this.expandedKeys = [];
  }
  //#endregion

  public onNodeClick(event: NodeClickEvent): void {
  }

  onApplyOnSelected(applyOption: number){
    this.hasPermissionUpdate = true;
    if (this.permissionData != undefined && this.permissionData != null){
      let setAllNone = (applyOption === this.noneAll) ? this.allowed : this.notAllowed;
      let setAllView = (applyOption === this.viewAll) ? this.allowed : this.notAllowed;
      let setAllEdit = (applyOption === this.editAll) ? this.allowed : this.notAllowed;
      this.permissionData.forEach((node1: any) => {
        //Level# 1
        if (node1.value != undefined || node1.value != null) {
          node1.isNone = (node1.isNone != this.featureAccessValue ) ? setAllNone : node1.isNone;
          node1.isView = (node1.isView != this.disabledId && node1.isView != 9 ) ? setAllView : node1.isView;
          node1.isEdit = (node1.isEdit != this.disabledId && node1.isEdit != 9 ) ? setAllEdit : node1.isEdit;

          node1.value.isNone = node1.isNone;
          node1.value.isView = node1.isView;
          node1.value.isEdit = node1.isEdit;
          this.updatePermissionOnSelectionChanged(node1.value);

          //Level# 2
          if (node1.items != undefined || node1.items != null && node1.items.length > 0) {
            node1.items.forEach((node2: any) => {
              node2.isNone = (node2.isNone != this.featureAccessValue ) ? setAllNone : node2.isNone;
              node2.isView = (node2.isView != this.disabledId && node2.isView != this.featureAccessValue ) ? setAllView : node2.isView;
              node2.isEdit = (node2.isEdit != this.disabledId && node2.isEdit != this.featureAccessValue ) ? setAllEdit : node2.isEdit;

              node2.value.isNone = node2.isNone;;
              node2.value.isView = node2.isView;
              node2.value.isEdit = node2.isEdit;
              this.updatePermissionOnSelectionChanged(node2.value);

              //Level# 3
              if (node2.items != undefined || node2.items != null && node2.items.length > 0) {
                node2.items.forEach((node3: any) => {
                  node3.isNone = (node3.isNone != this.featureAccessValue ) ? setAllNone : node3.isNone;
                  node3.isView = (node3.isView != this.disabledId && node3.isView != this.featureAccessValue) ? setAllView : node3.isView;
                  node3.isEdit = (node3.isEdit != this.disabledId && node3.isEdit != this.featureAccessValue) ? setAllEdit : node3.isEdit;

                  node3.value.isNone = node3.isNone;
                  node3.value.isView = node3.isView;
                  node3.value.isEdit = node3.isEdit;
                  this.updatePermissionOnSelectionChanged(node3.value);

                  //Level# 4
                  if (node3.items != undefined || node3.items != null && node3.items.length > 0) {
                    node3.items.forEach((node4: any) => {
                      node4.isNone = (node4.isNone != this.featureAccessValue ) ? setAllNone : node4.isNone;
                      node4.isView = (node4.isView != this.disabledId && node4.isView != this.featureAccessValue) ? setAllView : node4.isView;
                      node4.isEdit = (node4.isEdit != this.disabledId && node4.isEdit != this.featureAccessValue) ? setAllEdit : node4.isEdit;

                      node4.value.isNone = node4.isNone;
                      node4.value.isView = node4.isView;
                      node4.value.isEdit = node4.isEdit;
                      this.updatePermissionOnSelectionChanged(node4.value);
                    });
                  }
                });
              }
            });
          }
        }
      });
    }
  }
}