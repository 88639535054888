<div class="common-primary-box mt-4">
    <div class="primary-header">
        <h3 class="title-with-icon"><span class="custom-icons icon-mike"></span> Announcements</h3>
    </div>
    <div class="primary-body">
        <div class="space info-box-scroll">
            <div *ngIf="announcementList != null && announcementList.length > 0">
                <div class="announcements-box" *ngFor="let item of announcementList; let i = index;">
                    <h4 class="date-time">{{item.startDate | date:'MM/dd/yyyy' }} - {{item.endDate | date:'MM/dd/yyyy' }}</h4>
                    <h3 [ngClass]="{'announcementSystemOutage': item.announcementFor === 3}">{{item.title}} - {{item.details}}</h3>
                </div>
            </div>
            <div class="announcements-box" *ngIf="announcementList === null || announcementList.length === 0">
                <h3>No Announcements</h3>
            </div>
        </div>
    </div>
</div>
