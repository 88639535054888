import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonTabIds } from 'src/app/common/enum/common-tabs';
import { ModuleMasterIds } from 'src/app/common/enum/module-master';
import { ModuleTabIds } from 'src/app/common/enum/module-tab-master';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';

@Component({
  selector: 'app-cereus-common-tab',
  templateUrl: './cereus-common-tab.component.html',
  styleUrls: ['./cereus-common-tab.component.scss']
})

export class CereusCommonTabComponent implements OnInit {

  tabPermissionList: any[] = [];
  @Input() moduleMasterId: ModuleMasterIds;
  @Output() onTabChangedControlEvent = new EventEmitter<number>();
  @Output() selectedActiveTabId: number = 0;

  showWorkspace: boolean = false;
  showProgress: boolean = false;
  showStatistics: boolean = false;
  showScorecard: boolean = false;
  showDocument: boolean = false;
  showTaskMgmt: boolean = false;
  showCompleted: boolean = false; //Only using at TPN Module

  constructor(public _coreHelper: CoreHelperService,
    private changeDetectorRef: ChangeDetectorRef) { 
  }

  ngOnInit(): void {
    if (this.moduleMasterId == ModuleMasterIds.VOB){
      this.tabPermissionList = this._coreHelper.getVOBModulePermission();
    } 
    else if (this.moduleMasterId == ModuleMasterIds.UR){
      this.tabPermissionList = this._coreHelper.getURModulePermission();
    }
    else if (this.moduleMasterId == ModuleMasterIds.Billing){
      this.tabPermissionList = this._coreHelper.getBillingModulePermission();
    }
    else if (this.moduleMasterId == ModuleMasterIds.RCM){
      this.tabPermissionList = this._coreHelper.getRCMModulePermission();
    }
    else if (this.moduleMasterId == ModuleMasterIds.MedicalRecord){
      this.tabPermissionList = this._coreHelper.getMedicalRecordModulePermission();
    }
    else if (this.moduleMasterId == ModuleMasterIds.Payments){
      this.tabPermissionList = this._coreHelper.getPaymentsModulePermission();
    }
    else if (this.moduleMasterId == ModuleMasterIds.TPN){
      this.tabPermissionList = this._coreHelper.getTPNModulePermission();
    }
  }

  ngAfterViewInit() {
    this.loadPermissionByModule();
    this.changeDetectorRef.detectChanges();
  }

  loadPermissionByModule(){
    if(this.tabPermissionList.length>0){
      //VOB
      if (this.moduleMasterId === ModuleMasterIds.VOB){
        let filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.VOB_Workspace && r.isView == 1);
        this.showWorkspace = (filteredList != undefined && filteredList != null && filteredList.length > 0);
  
        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.VOB_Progress  && r.isView == 1);
        this.showProgress = (filteredList != undefined && filteredList != null && filteredList.length > 0);

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.VOB_Statistics  && r.isView == 1);
        this.showStatistics = (filteredList != undefined && filteredList != null && filteredList.length > 0);
      }
      //UR
      if (this.moduleMasterId == ModuleMasterIds.UR){
        let filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.UR_Workspace && r.isView == 1);
        this.showWorkspace = (filteredList != undefined && filteredList != null && filteredList.length > 0);
  
        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.UR_Progress  && r.isView == 1);
        this.showProgress = (filteredList != undefined && filteredList != null && filteredList.length > 0); 

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.UR_Statistics  && r.isView == 1);
        this.showStatistics = (filteredList != undefined && filteredList != null && filteredList.length > 0);
      }
      //Billing
      if (this.moduleMasterId == ModuleMasterIds.Billing){
        let filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.Billing_Workspace && r.isView == 1);
        this.showWorkspace = (filteredList != undefined && filteredList != null && filteredList.length > 0);
  
        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.Billing_Progress  && r.isView == 1);
        this.showProgress = (filteredList != undefined && filteredList != null && filteredList.length > 0); 

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.Billing_Statistics  && r.isView == 1);
        this.showStatistics = (filteredList != undefined && filteredList != null && filteredList.length > 0);
      }
      //RCM
      if (this.moduleMasterId == ModuleMasterIds.RCM){
        let filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.RCM_Workspace && r.isView == 1);
        this.showWorkspace = (filteredList != undefined && filteredList != null && filteredList.length > 0);
  
        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.RCM_Progress  && r.isView == 1);
        this.showProgress = (filteredList != undefined && filteredList != null && filteredList.length > 0);

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.RCM_Statistics  && r.isView == 1);
        this.showStatistics = (filteredList != undefined && filteredList != null && filteredList.length > 0);
      }

      //Medical Records
      if (this.moduleMasterId == ModuleMasterIds.MedicalRecord){
        let filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.MedicalRecord_Workspace && r.isView == 1);
        this.showWorkspace = (filteredList != undefined && filteredList != null && filteredList.length > 0);
  
        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.MedicalRecord_Progress  && r.isView == 1);
        this.showProgress = (filteredList != undefined && filteredList != null && filteredList.length > 0);

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.MedicalRecord_Statistics  && r.isView == 1);
        this.showStatistics = (filteredList != undefined && filteredList != null && filteredList.length > 0);
      }

      //Payments
      if (this.moduleMasterId == ModuleMasterIds.Payments){
        let filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.Payments_Workspace && r.isView == 1);
        this.showWorkspace = (filteredList != undefined && filteredList != null && filteredList.length > 0);
  
        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.Payments_Progress  && r.isView == 1);
        this.showProgress = (filteredList != undefined && filteredList != null && filteredList.length > 0);

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.Payments_Statistics  && r.isView == 1);
        this.showStatistics = (filteredList != undefined && filteredList != null && filteredList.length > 0);
      }

      //TPN - Third Party Nagotiations
      if (this.moduleMasterId == ModuleMasterIds.TPN){
        let filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.TPN_Workspace && r.isView == 1);
        this.showWorkspace = (filteredList != undefined && filteredList != null && filteredList.length > 0);
  
        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.TPN_Progress  && r.isView == 1);
        this.showProgress = (filteredList != undefined && filteredList != null && filteredList.length > 0);

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.TPN_Completed  && r.isView == 1);
        this.showCompleted = (filteredList != undefined && filteredList != null && filteredList.length > 0);

        filteredList = this.tabPermissionList.filter(r => r.tabMasterId == ModuleTabIds.TPN_Statistics  && r.isView == 1);
        this.showStatistics = (filteredList != undefined && filteredList != null && filteredList.length > 0);
      }
    }
  }

  onTabChanged_At_CommonControl(tabIndex: number) {
    this.selectedActiveTabId = tabIndex;
    this.onTabChangedControlEvent.emit(this.selectedActiveTabId);
  }
}
