import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService extends CoreHttpService {

  private announcementUrl: string = `${environment.apiUrl}/Announcement`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }
  /* Announcement Master API */
  getannouncementList(params: any): Observable<any> {
    return this.postParams(`${this.announcementUrl}/GetAnnouncementList`,"", params);
  }

  AddUpdateAnnouncement(model: any): Observable<any> {
    return this.post(`${this.announcementUrl}/AddUpdateAnnouncement`, model);
  }
  statusAnnouncement(model: any) {

    return this.post(`${this.announcementUrl}/SetActiveInactiveByAnnouncementId`, model);
  }
  historyAnnouncement(model: any) {

    return this.post(`${this.announcementUrl}/GetAnnouncementHistory`, model);
  }

  getMaintenanceMessages() {
    return this.get(`${this.announcementUrl}/GetMaintenanceMessages`)
  }

  getAnnouncementsListForDashboard() {
    return this.get(`${this.announcementUrl}/GetAnnouncementsListForDashboard`)
  }
}
