<div class="col-12 col-md-12 col-xxl-8">
    <ul class="nav nav-tabs common-nav-tab" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" *ngIf="this.showWorkspace">
            <button [ngClass]="(selectedActiveTabId == 0 ) ? 'nav-link active w-100' : 'nav-link w-100'"
                (click)="onTabChanged_At_CommonControl(0)" id="Workspace-tab" data-bs-toggle="tab" data-bs-target="#Workspace"
                type="button" role="tab" aria-controls="Workspace" aria-selected="true">Workspace</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="this.showProgress">
            <button [ngClass]="{'active': selectedActiveTabId == 1}" class="nav-link w-100" (click)="onTabChanged_At_CommonControl(1)"
                id="Progress-tab" data-bs-toggle="tab" data-bs-target="#Progress" type="button" role="tab"
                aria-controls="Progress" aria-selected="false">Progress</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="this.showCompleted">
            <button [ngClass]="{'active': selectedActiveTabId === 2 }" class="nav-link w-100" (click)="onTabChanged_At_CommonControl(2)"
                id="Completed-tab" data-bs-toggle="tab" data-bs-target="#Completed" type="button" role="tab" 
                aria-controls="Completed" aria-selected="false">Completed</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="this.showStatistics && !this.showCompleted">
            <button [ngClass]="(selectedActiveTabId == 2) ? 'nav-link active w-100' : 'nav-link w-100'" (click)="onTabChanged_At_CommonControl(2)"
                id="Statistics-tab" data-bs-toggle="tab" data-bs-target="#Statistics" type="button" role="tab"
                aria-controls="Statistics" aria-selected="false">Statistics</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="this.showScorecard">
            <button [ngClass]="{'active': selectedActiveTabId == 3}" class="nav-link w-100" (click)="onTabChanged_At_CommonControl(3)"
                id="Scorecard-tab" data-bs-toggle="tab" data-bs-target="#Scorecard" type="button" role="tab"
                aria-controls="Scorecard" aria-selected="false">Scorecard</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="this.showDocument">
            <button [ngClass]="{'active': selectedActiveTabId == 4}" class="nav-link w-100" (click)="onTabChanged_At_CommonControl(4)"
                id="Documents-tab" data-bs-toggle="tab" data-bs-target="#Documents" type="button" role="tab"
                aria-controls="Documents" aria-selected="false">Documents</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="this.showTaskMgmt">
            <button [ngClass]="(selectedActiveTabId == 5) ? 'nav-link active w-100' : 'nav-link w-100'"
                (click)="onTabChanged_At_CommonControl(5)" id="TaskManagement-tab" data-bs-toggle="tab" data-bs-target="#TaskManagement"
                type="button" role="tab" aria-controls="TaskManagement" aria-selected="false">Task Management</button>
        </li>        
    </ul>
</div>