import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-common-message',
  templateUrl: './common-message.component.html'
})
export class CommonMessageComponent implements OnInit {
  @Input() isCommonConfirm: boolean = false;
  @Input() commonDisplayText: string = "";
  @Input() commonTitle: string = "";
  @Output() commonCancelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() commonConfirmEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public commonConfirm(): void {
    this.commonConfirmPopupClose();
    this.commonConfirmEvent.emit();
  }
  public commonClose(): void {
    this.commonConfirmPopupClose();
    this.commonCancelEvent.emit();
  }

  public commonConfirmPopupClose(): void {
    this.isCommonConfirm = false;
  }
}
