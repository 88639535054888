import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';
import { admitFromVobRequestModel } from './vob/vob.classes';

@Injectable({
  providedIn: 'root'
})

export class VOBService extends CoreHttpService {
  callTaskClear = new Subject();
  exportExcel = new Subject<boolean>();
  exportPdf = new Subject<boolean>();
  public filter = false;
  adddFilterOpen() {
    this.filter = true;
  }
  filterClose() {
    this.filter = false;
  }
  public clearTask() {
    this.callTaskClear.next(true);
  }
  public exportExcelTask() {
    this.exportExcel.next(true);
  }
  public exportPdfTask() {
    this.exportPdf.next(true);
  }
  private VOBUrl: string = `${environment.apiUrl}/VOBService`;
  private authUrl: string = `${environment.apiUrl}/Authorization`;
  private StatusUrl: string = `${environment.apiUrl}/StatusMaster`;
  private FacilityStaffUrl: string = `${environment.apiUrl}/FacilityStaff`;
  private statisticUrl: string = `${environment.apiUrl}/Statistics`;

  public isAddVobManual = false;
  public isIssuePopupOpen = false;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  getVobCaseList(params: any) {
    return this.postParams(`${this.VOBUrl}/GetVobCaseList`, "",  params );
  }
  getVOBPlanType() {
    return this.post(`${this.VOBUrl}/GetVOBPlanType`, "");
  }
  getVOBThirdPartyNegotiations() {
    return this.post(`${this.VOBUrl}/GetVOBThirdPartyNegotiations`, "");
  }

  getVobCaseDetailsByPatientId(params: any) {
    return this.postParams(`${this.VOBUrl}/GetVobCaseDetailsByPatientId`, "", params );
  }

  GetFacilityMasterByPatientId(params: any) {
    return this.postParams(`${environment.apiUrl}/FacilityMaster/GetFacilityMasterByPatientId`, "", params );
  }

  AddUpdateVobService(model: any) {
    return this.post(`${this.VOBUrl}/AddUpdateVobService`, model);
  }
    
  RequestToVerifyVOB(model: any) {
    return this.post(`${this.VOBUrl}/RequestToVerifyVOB`, model);
  }
  
  ArchiveVobOrUrById(model: any) {
    return this.post(`${this.VOBUrl}/ArchiveVobOrUrById`, model);
  }
  
  getNotesByReferenceId(requestId: number, notesTypeId: number) {
    return this.get(`${environment.apiUrl}/Common/GetNotesByReferenceId?referenceId=${requestId}&notesTypeId=${notesTypeId}`);
  }
  updateVobActionDetailByCaseId(model: any) {
    return this.post(`${this.VOBUrl}/UpdateVobActionDetailByCaseId`, model);
  }
  getVobActionDetailsByVobId(params: any): Observable<any> {
    return this.postParams(`${this.VOBUrl}/GetVobActionDetailsByVobId`, "",  params );
  }
  getSubStatusMasterList(params: any) {
    return this.postParams(`${this.StatusUrl}/GetSubStatusMasterList`, "",  params );
  }
  getVOBExternalNoteResponseByRequestId(params: any) {
    return this.postParams(`${this.VOBUrl}/GetVOBExternalNoteResponseByRequestId`, "",  params );
  }

  getVOBHistoryByRequestId(requestId: number) {
    return this.get(`${this.VOBUrl}/GetVOBHistoryByRequestId?requestId=${requestId}`);
  }

  getVOBHistoryByPatientId(patientId: number) {
    return this.get(`${this.VOBUrl}/GetVOBHistoryByPatientId?patientId=${patientId}`);
  }

  getAuthHistoryByRequestId(requestId: number, historyType:number = 0) {
    return this.get(`${this.authUrl}/GetURHistoryByRequestId?requestId=${requestId}&historyType=${historyType}`);
  }

  getBillingHistoryByRequestId(requestId: number) {
    return this.get(`${environment.apiUrl}/BillingMaster/GetBillingHistoryByBillingId?requestId=${requestId}`);
  }

  getVobMasterByVobId(params: any) {
    return this.postParams(`${this.VOBUrl}/GetVobMasterByVobId`, "", params );
  }

  getVobPrecertificationByVobId(params: any) {
    return this.postParams(`${this.VOBUrl}/GetVobPrecertificationByVobId`, "", params );
  }

  vOBExclusionsByVobId(params: any) {
    return this.postParams(`${this.VOBUrl}/VOBExclusionsByVobId`, "", params );
  }

  getVobNotesByVobId(params: any) {
    return this.postParams(`${this.VOBUrl}/GetVobNotesByVobId`, "", params );
  }
  getTeamLeadbyFacilityId(params: any) {
    return this.postParams(`${this.FacilityStaffUrl}/GetTeamLeadbyFacilityId`, "", params );
  }

  getVobProgressData(params: any) {
    return this.get(`${this.VOBUrl}/GetVobProgressData`,  params );
  }

  getStatusByDepartmentId(departmentId: number, statusId: number): Observable<any> {
    return this.get(`${environment.apiUrl}/StatusMaster/GetStatusList?departmentId=${departmentId}&statusId=${statusId}`);
  }

  updateVobPatientToAdmitted(model: any): Observable<any> {
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return this._http.put(`${this.VOBUrl}/UpdateVobPatientToAdmitted`, "", { params: model, headers: headers });
  }

  UpdateVobPatientToAdmittedFacility(model: any): Observable<any> {
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    })
    return this._http.put(`${this.VOBUrl}/UpdateVobPatientToAdmittedFacility`, "", { params: model, headers: headers });
  }

  updateRequestAuthorizationFromVOB(model: any): Observable<any> {
    return this.put(`${this.VOBUrl}/UpdateRequestAuthorizationFromVOB`, model);
  }
  getVobWorkSpaceData(statusId: number, facilityId: number): Observable<any> {
    return this.get(`${this.VOBUrl}/Getvobworkspacedata?statusId=${statusId}&facilityId=${facilityId}`);
  }

  sendVobTofacility(model: any): Observable<any> {
    return this.put(`${this.VOBUrl}/SendVOBToFacility`, model)
  }
  getvobChartReport(params) {
    return this.post(`${this.statisticUrl}/GetVobStatisticsData`, params);
  }

  getVobAuthNotesById(referenceId: number, subReferenceId: number, isExternal: number, noteTypeId: number): Observable<any> {
    return this.get(`${environment.apiUrl}/Common/GetVobAuthNotesById?referenceId=${referenceId}&subReferenceId=${subReferenceId}
    &isExternal=${isExternal}&noteTypeId=${noteTypeId}`)
  }

  addUpdateVobAuthNotes(model: any) {
    return this.post(`${environment.apiUrl}/Common/AddUpdateVobAuthNotes`, model)
  }
  getAuthStatisticsChart(params) {
    return this.post(`${this.statisticUrl}/GetURStatistics`, params);
  }
  getBilledChartReport(params) {
    return this.post(`${this.statisticUrl}/GetBillingStatistics`,params);
  }
  GetVobScoreCardsData(params) {
    return this.post(`${this.statisticUrl}/GetVobScoreCardsData`, params);
  }

  getVobFacilityDemographicInfo(requestId: number): Observable<any> {
    let param = { requestId: requestId }
    return this.postParams(`${this.VOBUrl}/GetVobFacilityDemographicInfo`, "", param )
  }
  getVobTeamPerfomance(facilityId: number, statusId: number): Observable<any> {
    return this.get(`${this.statisticUrl}/GetVobTenureGoalsReport?facilityId=${facilityId}&statusId=${statusId}`);
  }

  getVobDueDate(requestId: number): Observable<any> {
    return this.get(`${this.VOBUrl}/GetVobDueDate?requestId=${requestId}`);
  }

  updateVobDueDate(vobId: number, dueDate: string): Observable<any> {
    if(dueDate)
    {
      let strDueDate: string = JSON.stringify(dueDate);
      return this.put(`${this.VOBUrl}/UpdateVobDueDate?vobId=${vobId}&dueDate=${strDueDate}`, "");
    } else {
      return this.put(`${this.VOBUrl}/UpdateVobDueDate?vobId=${vobId}`, "");
    }

  }
}
