<kendo-dialog title="{{commonTitle}}" (close)="commonClose()" class="kendo-modal"
  [ngClass]="isCommonConfirm ? 'modal-md' : 'modal-sm'">
  <h6 class="text-center">{{commonDisplayText}}</h6>
  <kendo-dialog-actions class="d-block text-center" *ngIf="!isCommonConfirm">
    <button kendoButton class="btn btn-primary btn-sm" (click)="commonClose()">Ok</button>
  </kendo-dialog-actions>
  <kendo-dialog-actions class="d-block text-center" *ngIf="isCommonConfirm">
    <button kendoButton class="btn btn-primary btn-sm" (click)="commonConfirm()">Yes</button>
    <button kendoButton class="btn btn-primary btn-sm" (click)="commonClose()">No</button>
  </kendo-dialog-actions>
</kendo-dialog>
<div kendoDialogContainer></div>