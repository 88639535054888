import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { Modules } from '../../providers/core-role-access-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private coreHelperService: CoreHelperService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    var userData = this.coreHelperService.getLoggedInUserDetail();
    if (this.coreHelperService.isNullOrUndefined(userData)) {
      return this.router.createUrlTree(['']);
    } else {
      if (this.coreHelperService.checkIsAgreedAgreement() == 0) {
        this.router.createUrlTree(['/non-auth/user-agreement']);
        return true;
      }
      // code update as per new changes in permission
      return true
    }
  }
  async logout() {
    this.coreHelperService.clearLocalStorage();
    await this.oktaAuth.signOut();
  }

  public get module() {
    return Modules;
  }
}
