import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreHelperService } from '../core-helper/core-helper.service';
import { CommonNotificationService } from 'src/app/common/service/common-notification.service';
@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService,
    private _coreHelper: CoreHelperService,
    private _commonNotificationService: CommonNotificationService) { }
  count = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!(req.url.includes('ExecutiveDashboard'))) {
      this.spinner.show('global')
    }
    this.count++;
    return next.handle(req)
      .pipe(tap(
        (event: any) => {
          if (!this._coreHelper.isNullOrUndefined(event) && !this._coreHelper.isNullOrUndefined(event.body)) {
            if (!event?.body?.apiStatus) {
              if (!this._coreHelper.isNullOrUndefined(event?.body?.apiMessage)) {
                var res = event?.body?.apiMessage?.trim().toLowerCase().replace(/\s/g, "")
                if (res != 'nodatafound!') {
                  this._commonNotificationService.displayError('Application error found.');
                }
              }
            }
          }
          setTimeout(() => {
            this.dropdownAction();
          }, 1000)
        }
      ), finalize(() => {
        this.count--;
        if (this.count == 0) {
          this.spinner.hide('global')
        }
      })
      );
  }

  dropdownAction = () => {
    var actionCollection = document.getElementsByClassName("dropdown-actions-fixed")
    var arrayActionCollection = Array.from(actionCollection);
    this._coreHelper.dropDownPositionUpdate(arrayActionCollection);
  }

}
