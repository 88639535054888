<aside class="sidebar-main">
        <div class="sidebar-header d-flex justify-content-between">
                <a class="logo-box"
                        [routerLink]="this.coreHelperService.getLoggedInUserDomain() == 0 ? ['auth/dashboard'] : ['/auth/facility-dashboard']">
                        <img src="../../../../assets/img/cereus-logo.svg" alt="Cereus" class="cereus-logo">
                        <img src="../../../../assets/img/cereus-logo-icon.svg" alt="Cereus" class="cereus-icon">
                </a>
                <div class="toggle-icon hidden-mobile-nav" id="sidebarToggle" (click)="addBodyClass()">
                        <span class='icon-nav-arrow custom-icons toggle-arrow'>
                                <span class='path1'></span>
                                <span class='path2'></span>
                        </span>
                </div>
                <div class="visible-mobile-nav" (click)="addBodyClassMobile()">
                        <span></span>
                        <span></span>
                        <span></span>
                </div>
        </div>
        <div class="sidebar-content">
                <div class="accordion sidebar-nav" id="sidebarAccordion">
                        <div class="accordion-item" (click)="redirect()">
                                <h2 class="accordion-header" id="flush-headingOne">
                                        <span class="accordion-button sidebar-link statick-link"
                                                (click)="setTitle('Dashboard', true)">
                                                <span class="sidebar-icon" (click)="addBodyClass()">
                                                        <span class="nav-icon custom-icons icon-dashboard"></span>
                                                </span>
                                                <span class="sidebar-text"> Dashboard</span>
                                        </span>
                                </h2>
                        </div>
                        <div class="accordion-item" *ngIf="this.isResourceFound" >
                                <h2 class="accordion-header" id="flush-headingTwo">
                                        <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                                aria-controls="flush-collapseTwo">
                                                <span class="sidebar-icon" (click)="addBodyClass()"><span
                                                                class="nav-icon custom-icons icon-briefcase"></span></span>
                                                <span class="sidebar-text">Resource</span>
                                        </span>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingTwo" data-bs-parent="#sidebarAccordion">
                                        <div class="accordion-body">
                                                <ul class="sub-nav">
                                                        <li *ngFor="let resource of resourceModuleList">
                                                                <a class="k-link"
                                                                        [routerLink]="resource.navigationRoute"
                                                                        (click)="setTitle(moduleName.FacilityMaster)">{{resource.moduleName}}
                                                                </a>
                                                        </li>
                                                </ul>
                                        </div>
                                </div>
                        </div>
                        <div class="accordion-item" *ngIf="this.isServicesFound" >
                                <h2 class="accordion-header" id="flush-headingThree">
                                        <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseThree" aria-expanded="false"
                                                aria-controls="flush-collapseThree">
                                                <span class="sidebar-icon" (click)="addBodyClass()"><span
                                                                class="nav-icon custom-icons icon-briefcase-medical"></span></span>
                                                <span class="sidebar-text">Services</span>
                                        </span>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingThree" data-bs-parent="#sidebarAccordion">
                                        <div class="accordion-body">
                                                <li *ngFor="let service of serviceModuleList">
                                                        <a class="k-link"
                                                                [routerLink]="service.navigationRoute"
                                                                (click)="setTitle(moduleName.FacilityMaster)">{{service.moduleName}}
                                                        </a>
                                                </li>
                                        </div>
                                </div>
                        </div>
                        <div class="accordion-item" *ngIf="this.isReportsFound" >
                                <h2 class="accordion-header" id="flush-Fourth">
                                        <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#reportCallapse" aria-expanded="false"
                                                aria-controls="reportCallapse">
                                                <span class="sidebar-icon" (click)="addBodyClass()"><span
                                                                class="nav-icon custom-icons icon-dasktop"></span></span>
                                                <span class="sidebar-text">Reports</span>
                                        </span>
                                </h2>
                                <div id="reportCallapse" class="accordion-collapse collapse"
                                        aria-labelledby="flush-Fourth" data-bs-parent="#sidebarAccordion">
                                        <div class="accordion-body">
                                                <li *ngFor="let report of reportModuleList">
                                                        <a class="k-link"
                                                                [routerLink]="report.navigationRoute"
                                                                (click)="setTitle(moduleName.FacilityMaster)">{{report.moduleName}}
                                                        </a>
                                                </li>
                                        </div>
                                </div>
                        </div>
                        <div class="accordion-item"  *ngIf="this.isAdministrationFound">
                                <h2 class="accordion-header" id="flush-fifth">
                                        <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                                                data-bs-target="#adminCallapse" aria-expanded="false"
                                                aria-controls="adminCallapse">
                                                <span class="sidebar-icon" (click)="addBodyClass()"><span
                                                                class="nav-icon custom-icons icon-shield"></span></span>
                                                <span class="sidebar-text">Administration</span>
                                        </span>
                                </h2>
                                <div id="adminCallapse" class="accordion-collapse collapse"
                                        aria-labelledby="flush-fifth" data-bs-parent="#sidebarAccordion">
                                        <div class="accordion-body">
                                                <li *ngFor="let admin of adminModuleList">
                                                        <a class="k-link"
                                                                [routerLink]="admin.navigationRoute"
                                                                (click)="setTitle(moduleName.FacilityMaster)">{{admin.moduleName}}
                                                        </a>
                                                </li>
                                        </div>
                                </div>
                        </div>
                        <div class="accordion-item" [hidden]="true">
                                <h2 class="accordion-header" id="flush-headingOne">
                                        <span [routerLink]="['auth/invoicing']" routerLinkActive="active-link"
                                                [routerLinkActiveOptions]="{exact: true}"
                                                class="accordion-button sidebar-link statick-link"
                                                (click)="setTitle('Invoicing', true)">
                                                <span class="sidebar-icon" (click)="addBodyClass()">
                                                        <span class="nav-icon custom-icons icon-list-lg"></span>
                                                </span>
                                                <span class="sidebar-text"> Invoicing</span>
                                        </span>
                                </h2>
                        </div>
                </div>
        </div>
        <div class="version-text">
                <p>{{versionText}}</p>
        </div>
</aside>

<!-- Overlay Responsive -->
<div class="responsive-overlay" (click)="addBodyClass()"></div>