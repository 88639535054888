<div class="common-border table-responsive only-pager-grid">

    <kendo-grid [kendoGridBinding]="documentList" #documentSummaryList="kendoGrid" [pageSize]="10"
        [pageable]="{pageSizes: pageSizes}" [sortable]="{ mode: 'multiple' }" [sort]="state.sort!"
        (cellClick)="onCellClick($event)" (dblclick)="addDocumentOpen(null, null)" filterable="menu">
        <kendo-grid-column field="fileId" title="File ID" [filterable]="true" [width]="130">
        </kendo-grid-column>
        <kendo-grid-column field="createdDate" title="Created On" [filterable]="true" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.createdDate | localDateTime}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="expiryDate" title="Due/Expiration" [filterable]="true" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.expiryDate | localDateTime}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="fileName" title="File Name" [filterable]="true" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div class="files-links-box">
                    <a [href]="dataItem.filePath" [download]="dataItem.fileName" target="_blank"
                        class="file-link">{{dataItem.fileName}}</a>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="categoryName" title="Category" [filterable]="true" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Created By" [filterable]="true" [width]="200">
        </kendo-grid-column>
        <!-- 
        <kendo-grid-column field="teamMember" title="Team Member" [filterable]="true" [width]="200">
        </kendo-grid-column> -->
        <kendo-grid-column field="status" title="Status" [filterable]="true" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a (click)="statusChangeOpen({ id: dataItem.fileId,text:'Are you sure you want to change Document status to Archived?', statusId: 2 })" 
                        *ngIf="dataItem.status.toLowerCase() == 'incoming'"
                            class='status-label-btn bg-btn-danger'>{{dataItem.status}}</a>
                        <a (click)="statusChangeOpen({ id: dataItem.fileId,text:'Are you sure you want to change Document status to Archived?', statusId: 3 })"
                            *ngIf="dataItem.status.toLowerCase() == 'classified'"
                            class='status-label-btn bg-btn-success'>{{dataItem.status}}</a>
                        <a *ngIf="dataItem.status.toLowerCase() == 'archived'"
                            class='status-label-btn bg-btn-gray'>{{dataItem.status}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="teamMember" title="Responsible" [filterable]="true" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
            [headerClass]="{'text-center': true}">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div class="dropdown table-dropdown text-center">
                    <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button"
                        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="custom-icons icon-list-menu"></i>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a class="dropdown-item" href="javascript:;"
                                (click)="addDocumentOpen(dataItem.fileId, dataItem.status)"><span
                                    class="custom-icons icon-eye"></span>View</a>
                        </li>
                        <li><a class="dropdown-item" href="javascript:;" (click)="historyOpen(dataItem.fileId)"><span
                                    class="custom-icons icon-list"></span>History</a>
                        </li>
                    </ul>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages [pagerItems]="'Document List'" [pagerOf]="'of'">
        </kendo-grid-messages>
        <kendo-grid-pdf fileName="DocumentSummary.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
            [landscape]="true" [scale]="0.5">
            <!----custom div-->

            <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
            </kendo-grid-pdf-margin>
            <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
                <div class="page-template">
                    <div class="header" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">

                        <div class="address">
                            <div class="for">
                                <h3>Invoice For</h3>
                                <p>
                                    Antonio Moreno<br /> Naucalpan de Juárez<br />
                                    México D.F., Mexico, 53500
                                </p>
                            </div>
                            <div class="from">
                                <h3>From</h3>
                                <p>
                                    Hanna Moos <br /> Lützowplatz 456<br /> Berlin,
                                    Germany, 10785
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <div style="float: right">
                            Page {{ pageNum }} of {{ totalPages }}
                        </div>
                    </div>
                </div>
            </ng-template>
            <!----custom div-->
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="documentSummary.xlsx">
        </kendo-grid-excel>

    </kendo-grid>
</div>

<!-- Add Document Popup Start -->
<kendo-dialog title="{{documentTitle}}" *ngIf="addDocument" (close)="addDocumentClose()" class="kendo-modal modal-790">
    <div class="common-popup-space">
        <form [formGroup]="addDocumentForm">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Created Date</label>
                        <input type="date" class="form-control" placeholder="Created Date"
                            formControlName="documentDate" [attr.disabled]="true">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Created by</label>
                        <input type="text" class="form-control" placeholder="Created by"
                            [value]="userDetail" disabled>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Expiry Date</label>
                        <kendo-datepicker calendarType="classic" formControlName="expiryDate" [disabled]="isViewMode"
                            [ngClass]="{ 'is-invalid':  addDocumentForm.get('expiryDate').errors && submitted}">
                        </kendo-datepicker>
                        <div *ngIf=" addDocumentForm.get('expiryDate').errors" class="invalid-feedback">
                            {{ displayMessage['expiryDate'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Document Type</label>
                        <kendo-dropdownlist [data]="documentTypeListItems" [textField]="'text'" [valueField]="'value'"
                            [valuePrimitive]="true" formControlName="documentTypeId" [disabled]="isViewMode"
                            [defaultItem]="{ text: 'Select Document Type', value: null }"
                            [ngClass]="{ 'is-invalid':   displayMessage['documentTypeId']}"
                            (valueChange)=" getCategoryListByDocumentTypeId($event)">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['documentTypeId']" class="invalid-feedback">
                            {{ displayMessage['documentTypeId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <div class="label-with-icon">
                            <label class="form-label">Category (Department) </label>
                        </div>
                        <kendo-dropdownlist [data]="categoryMasterData" [textField]="'categoryName'"
                            [disabled]="isViewMode" [valueField]="'documentTypeMasterId'" [valuePrimitive]="true"
                            formControlName="categoryId"
                            [defaultItem]="{ categoryName: 'Select Category', categoryId: null }"
                            [ngClass]="{ 'is-invalid':   displayMessage['categoryId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['categoryId']" class="invalid-feedback">
                            {{ displayMessage['categoryId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <div class="label-with-icon">
                            <label class="form-label">Sub Category</label>
                        </div>
                        <kendo-dropdownlist [data]="subCategoryMasterData" [textField]="'subCategoryName'"
                            [valueField]="'documentTypeMasterId'" [valuePrimitive]="true" [disabled]="isViewMode"
                            formControlName="subCategoryId"
                            [defaultItem]="{ subCategoryName: 'Select Sub Category', subCategoryId: null }"
                            [ngClass]="{ 'is-invalid':   displayMessage['subCategoryId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['subCategoryId']" class="invalid-feedback">
                            {{ displayMessage['subCategoryId'] }}
                        </div>
                    </div>
                </div>
                <div *ngIf="addDocumentForm.value.documentTypeId ===2" class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Patient</label>
                        <kendo-dropdownlist [data]="patientList" formControlName="patientId" [filterable]="true"
                            (filterChange)="handleFilter($event)" [disabled]="isViewMode"
                            [defaultItem]="{ patientName: 'Select', patientId: null }" [textField]="'patientName'"
                            [valueField]="'patientId'" [valuePrimitive]="true"
                            [ngClass]="{ 'is-invalid': submitted && addDocumentForm.value.patientId == 0}">
                        </kendo-dropdownlist>
                        <div *ngIf="submitted && addDocumentForm.value.patientId == 0" class="invalid-feedback">
                            please select patient
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Tags</label>
                        <kendo-multiselect (keydown.enter)="addTag($event)" [data]="documentsTags" formControlName="tags"
                            [textField]="'tagName'" [valueField]="'tagValue'" [valuePrimitive]="true"
                            [disabled]="isViewMode" [ngClass]="{ 'is-invalid':   displayMessage['tags']}">>
                        </kendo-multiselect>
                        <div *ngIf="displayMessage['tags']" class="invalid-feedback">
                            {{ displayMessage['tags'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">File</label><br />
                        <a [href]="filePath" [download]="fileName" target="_blank"
                            *ngIf="isDocumentEdit && filePath !=null && fileName != null"> {{fileName}}</a>
                    </div>
                </div>
                <div *ngIf="isDocumentEdit" class="col-12 col-md-12">
                    <div class="common-primary-box">
                        <div class="primary-header">
                            <div class="box-title">
                                <h3>Notes</h3>
                            </div>
                        </div>
                        <div class="primary-body">
                            <div class="space-20">
                                <ul class="nav nav-tabs common-nav-tab inner-square-nav-tav mr-btm" id="NoteTab"
                                    role="tablist">
                                    <li class="nav-item" role="presentation" *ngIf="this._coreHelper.getLoggedInUserDomain() == 0">
                                        <button class="nav-link active w-100" id="Internal-tab" data-bs-toggle="tab"
                                            data-bs-target="#Internal" type="button" role="tab" aria-controls="Internal"
                                            aria-selected="false">Internal</button>
                                    </li>
                                    <li class="nav-item" role="presentation" *ngIf="this._coreHelper.getLoggedInUserDomain() == 1">
                                        <button class="nav-link w-100" id="External-tab" data-bs-toggle="tab"
                                            data-bs-target="#External" type="button" role="tab" aria-controls="External"
                                            aria-selected="true">External</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link w-100" id="History-tab" data-bs-toggle="tab"
                                            data-bs-target="#History" type="button" role="tab" aria-controls="History"
                                            aria-selected="false">History </button>
                                    </li>

                                </ul>
                                <div class="tab-content" id="NoteTabContent">
                                    <div class="tab-pane fade show active" id="Internal" role="tabpanel"
                                        aria-labelledby="Internal-tab">
                                        <div class="form-group mb-0">
                                            <label class="form-label" for="current">Current</label>
                                            <textarea formControlName="internalNotes" class="form-control"
                                                placeholder="Enter" id="current" [disabled]="isViewMode" maxlength="450"
                                                [ngClass]="{ 'is-invalid':   displayMessage['internalNotes']}"></textarea>
                                            <div *ngIf="displayMessage['internalNotes']" class="invalid-feedback">
                                                {{ displayMessage['internalNotes'] }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="External" role="tabpanel"
                                        aria-labelledby="External-tab">
                                        <div class="form-group mb-0">
                                            <label class="form-label" for="current1">Current</label>
                                            <textarea formControlName="externalNotes" class="form-control"
                                                placeholder="Enter" id="current1" [disabled]="isViewMode"
                                                [ngClass]="{ 'is-invalid':   displayMessage['externalNotes']}" maxlength="450"></textarea>
                                            <div *ngIf="displayMessage['externalNotes']" class="invalid-feedback">
                                                {{ displayMessage['externalNotes'] }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="History" role="tabpanel"
                                        aria-labelledby="History-tab">
                                        <h5 class="my-4">History not available </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton *ngIf="!isViewMode" (click)="saveDocument()"
            class="btn btn-primary btn-md">Classify</button>
        <button kendoButton (click)="addDocumentClose()" class="btn btn-cancel btn-md">Cancel</button>

    </kendo-dialog-actions>
</kendo-dialog>
<!-- Add Document Popup End -->

<!-- History Popup Start -->
<kendo-dialog title="History" *ngIf="history" (close)="historyClose()"
    class="kendo-modal modal-720 modal-remove-button">
    <div class="common-popup-info">
        <div class="common-border table-responsive only-pager-grid">
            <kendo-grid [data]="documentHistory" [pageSize]="state.take!" [skip]="state.skip!" [sort]="state.sort!"
                [sortable]="true" [pageable]="false" filterable="menu" (dataStateChange)="dataStateChange($event)">
                <kendo-grid-column field="action" title="Actions" [width]="160" [filterable]="true">
                </kendo-grid-column>
                <kendo-grid-column field="byUser" title="By User" [width]="160" [filterable]="true"></kendo-grid-column>
                <kendo-grid-column field="byDate" title="By Date" [width]="160" [filterable]="true">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{dataItem.byDate | localDateTime}}</span>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
</kendo-dialog>
<!-- History Popup End -->

<!-- Please Confirm Popup Start -->
<kendo-dialog title="Change Status" *ngIf="statusChange" (close)="statusChangeClose()"
    class="kendo-modal modal-sm text-center">
    <div class="common-popup-alert">
        <!-- <h6 class="text-center">{{paramObj.text}}</h6> -->
        <kendo-dialog-actions class="d-block text-center">
            <button kendoButton class="btn btn-primary btn-sm" (click)="DocumentStatusChange()">Archive</button>
            <button kendoButton class="btn btn-cancel btn-sm" (click)="statusChangeClose()">Cancel</button>
        </kendo-dialog-actions>
    </div>
</kendo-dialog>
<!-- Please Confirm Popup End -->