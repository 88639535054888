import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';
import { GetLoginDetailsModel } from './permission.classes';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends CoreHttpService { 

  private accessibilityUrl: string = `${environment.apiUrl}/Permission`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }
  
  getModuleFeatureList(): Observable<any> {
    return this.get(`${this.accessibilityUrl}/getModuleFeatureList`);
  }

  getSectionModuleMasterList(moduleMasterId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/getSectionModuleMasterList?moduleMasterId=${moduleMasterId}`);
  }
 
  addUpdateSectionModuleMaster(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/addUpdateSectionModuleMaster`, model);
  }

  getSectionMasterList(sectionMasterId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/getSectionMasterList?sectionMasterId=${sectionMasterId}`);
  }
 
  addUpdateSectionMaster(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/addUpdateSectionMaster`, model);
  }

  addUpdateModuleTabMaster(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/AddUpdateModuleTabMaster`, model);
  }

  getModuleTabMasterList(tabMasterId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/getModuleTabMasterList?tabMasterId=${tabMasterId}`);
  }

  addUpdateModuleCardMaster(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/AddUpdateModuleTabCardMaster`, model);
  }

  getModuleCardMasterList(tabCardMasterId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/GetModuleTabCardMasterList?tabCardMasterId=${tabCardMasterId}`);
  }
  
  getModuleElementTypeList(elementTypeId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/GetModuleElementTypeList?elementTypeId=${elementTypeId}`);
  }

  addUpdateModuleElementType(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/AddUpdateModuleElementType`, model);
  }

  getModuleElementTransList(elementTypeId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/GetModuleElementTransList?elementTypeId=${elementTypeId}`);
  }

  addUpdateModuleElementTrans(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/AddUpdateModuleElementTrans`, model);
  }

  
  getRoleInfoByRoleId(roleId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/GetRoleInfoByRoleId?roleId=${roleId}`);
  }

  addUpdateRolesTransaction(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/AddUpdateRolesTransaction`, model);
  }

  getTeamsTransactionList(teamId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/GetTeamsTransactionList?teamId=${teamId}`);
  }

  addUpdateTeamsTransaction(model: any): Observable<any> {
    return this.post(`${this.accessibilityUrl}/AddUpdateTeamsTransaction`, model);
  }

  //#region User Permission Setup
  getUserPermissionByUserId(userId): Observable<any> {
    return this.get(`${this.accessibilityUrl}/GetUserPermissionByUserId?userId=${userId}`);
  }
  updateUserPermissionByUserId(model:any){
    return this.post(`${this.accessibilityUrl}/UpdateUserPermissionByUserId`, model);
  }
  //#endregion

  setActiveInactivePermission(model:any){
    return this.post(`${this.accessibilityUrl}/SetActiveInactivePermission`, model);
  }

  getLoginDetailsByUserName(model:GetLoginDetailsModel){
    return this.post(`${this.accessibilityUrl}/GetLoginDetailsByUserName`, model);
  }

  getPermissionByTab(tabSectionId: number): Observable<any> {
    return this.get(`${this.accessibilityUrl}/GetPermissionByTab?tabSectionId=${tabSectionId}`);
  }

  getUserInfo(model:GetLoginDetailsModel){
    return this.post(`${this.accessibilityUrl}/GetUserInfo`, model);
  }
}
